import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { addressDetailsFormModule } from 'go-modules/payment-panel/address-details-form';
import { licenseDetailsFormModule } from 'go-modules/payment-panel/license-details-form';
import { productDetailsFormModule } from 'go-modules/payment-panel/product-details-form';
import { payWithCardModule } from 'go-modules/payment-panel/pay-with-card';
import { EnterInformationComponent } from './enter-information.component';
import './style.less';

export const enterInformationModule = angular.module('enterInformationModule', [
	angularTranslate,
	addressDetailsFormModule,
	licenseDetailsFormModule,
	productDetailsFormModule,
	payWithCardModule
])
	.component('enterInformation', EnterInformationComponent)
	.name;
