import {
	ChangeDetectionStrategy,
	Component
} from '@angular/core';

@Component({
	selector: 'tool-unavailable-error',
	template: require('./tool-unavailable-error.component.html'),
	styles: [require('./tool-unavailable-error.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolUnavailableErrorComponent {
	constructor () {}
}
