import { IComponentOptions } from 'angular';
import { ActivityEditorWizardViewController } from './activity-editor-wizard-view.controller';
import template from './activity-editor-wizard-view.component.html';

export const ActivityEditorWizardViewComponent = {
	controller: ActivityEditorWizardViewController,
	require: {
		wizardController: '^wizard'
	},
	template
} as IComponentOptions;
