import { NgModule } from '@angular/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { AccountEditorModule } from './components/account-editor';
import { UserSessionExpirationModule } from 'ngx/lti/src/components/user-session-expiration';
import { AppErrorModule } from 'ngx/lti/src/app-error/app-error.module';

@NgModule({
	imports: [
		GoMaterialModule,
		AccountEditorModule,
		UserSessionExpirationModule,
		AppErrorModule
	]
})
export class LTIModule {}
