import type { PaymentMethodPayload } from 'braintree-web-drop-in';
import { LICENSE_PRODUCT_SEAT_TYPES } from '../../services/group/product';
import type { Product } from '../../services/group/product';
import type { Transaction } from '../../braintree/braintree.service';
import type { LicenseDetailsForm } from '../license-details-form/license-details-form.controller';
import { NgxPurchaseTypeService } from 'ngx/go-modules/src/services/purchase-type/purchase-type.service';

export class PaymentCompleteController {
	public paymentMethod: PaymentMethodPayload;
	public product: Product;
	public group: any;
	public transaction: Transaction;
	public options;
	public licenseDetails: LicenseDetailsForm;

	/* @ngInject */
	constructor (
		public ngxPurchaseTypeService: NgxPurchaseTypeService,
		private $element: ng.IRootElementService,
		private goPrintService: any
	) {}

	public get licenseTypeTranslationKey (): string {
		return this.licenseDetails.selectedProduct.licenseProduct.seat_type === LICENSE_PRODUCT_SEAT_TYPES.USER ?
			'payment-panel-license-details_license-type-multi-course' :
			'payment-panel-license-details_license-type-single-course';
	}

	public printOrderDetails () {
		this.goPrintService.print(
			this.$element[0].querySelector('.order-details-body'),
			'Order Details:'
		);
	}
}
