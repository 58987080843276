/* @ngInject */
export function environmentLoggingConfig (ENVIRONMENT_CONSTANTS, $logProvider) {
	$logProvider.debugEnabled(ENVIRONMENT_CONSTANTS.ENVIRONMENT !== ENVIRONMENT_CONSTANTS.PROD);
}

/* @ngInject */
export function compileProviderConfig (ENVIRONMENT_CONSTANTS, $compileProvider) {
	$compileProvider.debugInfoEnabled(ENVIRONMENT_CONSTANTS.ENVIRONMENT !== ENVIRONMENT_CONSTANTS.PROD);
	$compileProvider.commentDirectivesEnabled(ENVIRONMENT_CONSTANTS.ENVIRONMENT !== ENVIRONMENT_CONSTANTS.PROD);
	$compileProvider.cssClassDirectivesEnabled(ENVIRONMENT_CONSTANTS.ENVIRONMENT !== ENVIRONMENT_CONSTANTS.PROD);
}

/* @ngInject */
export function sessionManagerConfig (appData, sessionManagerConfigProvider) {
	// Configure token storage key
	sessionManagerConfigProvider.set('tokenStorageKey', 'lti-app-token');
	sessionManagerConfigProvider.set('tokenStorageKeyPrefix', 'goreact-');

	// Handle access token getter
	sessionManagerConfigProvider.accessTokenGetter = () => {
		return appData.auth.accessToken;
	};
}

/* @ngInject */
export const unhandledRejectionConfig = ($qProvider) => {
	$qProvider.errorOnUnhandledRejections(false);
};
