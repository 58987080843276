import * as angular from 'angular';
import { wrapAppDataRunBlock } from './app-data.run';

import { userModel } from 'go-modules/models/user';
import { groupDepModel } from 'go-modules/models/group-dep';
import { activityModel } from 'go-modules/models/activity';
import { useTypeModel } from 'go-modules/models/use-type';
import { goAppEnvModule } from 'go-modules/go-app-env';

export const appDataModule = angular
	.module('app.data', [
		userModel,
		groupDepModel,
		activityModel,
		useTypeModel,
		goAppEnvModule
	])
	.run(wrapAppDataRunBlock);
