import { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import { States } from 'go-modules/enums/states.enum';

/* @ngInject */
export const routingConfig = (
	$locationProvider,
	$stateProvider: StateProvider,
	$urlRouterProvider: UrlRouterProvider
) => {
	// html5 mode
	$locationProvider.html5Mode({ enabled: true, requireBase: false });
	$stateProvider
		.state(States.DASHBOARD_ROOT, {
			url: '/',
			abstract: true,
			template: '<app-route-view></app-route-view>'
		})
		.state(States.DASHBOARD_FOLDER_VIEW, {
			url: 'folder/:folder_id?modalOpen',
			reloadOnSearch: false,
			template: '<ngx-folder-view></ngx-folder-view>',
			resolve: {
				redirectIfdontHaveCourseLevelLTI: (appData, $state) => {
					if (!appData.hasCourseLevelLTI) {
						$state.go(States.DASHBOARD_ACTIVITY_VIEW, {activity_id: appData.activity.activity_id});
					}
				}
			}
		})
		.state(States.DASHBOARD_FOLDERS, {
			url: 'folders/:id',
			template: '<ngx-folders-view></ngx-folders-view>',
			resolve: {
				redirectTo: (appData, $state) => {
					if (appData.hasCourseLevelLTI) {
						$state.go(States.DASHBOARD_FOLDER_VIEW, {folder_id: appData.group_id});
					} else {
						$state.go(States.DASHBOARD_ACTIVITY_VIEW, {activity_id: appData.activity.activity_id});
					}
				}
			}
		})
		.state(States.DASHBOARD_ACTIVITY_VIEW, {
			url: 'activities/:activity_id',
			template: '<ngx-activity-view></ngx-activity-view>'
		})
		.state(States.DASHBOARD_SESSION_VIEW, {
			url: 'sessions/:session_id?viewMode&feedbackMode',
			template: '<ngx-session-view></ngx-session-view>',
			params: {
				viewMode: null,
				feedbackMode: null
			}
		})
		.state(States.DASHBOARD_WIZARD, {
			url: 'wizard?{editActivity:bool}&{pay:bool}&{setupAccount:bool}&{setupActivity:bool}&{setupCourse:bool}&{setupLicense:bool}',
			reloadOnSearch: false,
			template: '<wizard-route-view></wizard-route-view>'
		})
		.state(States.DASHBOARD_QUICKSTART_GUIDE, {
			url: 'quickstart-guide',
			template: '<quick-start-guide-route-view></quick-start-guide-route-view>'
		})
		.state(States.DASHBOARD_LICENSE_MANAGEMENT, {
			url: 'license-management',
			template: '<license-management-route-view></license-management-route-view>'
		})
		.state(States.LICENSE_SEATS_MANAGEMENT, {
			url: 'license-management/:licenseId/seats',
			template: '<license-seats-management-route-view></license-seats-management-route-view>'
		})
		.state(States.DASHBOARD_LICENSE_MANAGEMENT_ORG, {
			url: 'license-management/:orgId',
			template: '<license-management-route-view></license-management-route-view>'
		})
		.state(States.DASHBOARD_ARCHIVE, {
			url: 'archive',
			template: '<ngx-archive-view></ngx-archive-view>'
		})
		.state(States.DASHBOARD_SETTINGS, {
			url: 'settings?tab',
			template: '<ngx-user-settings></ngx-user-settings>',
			params: {
				tab: null
			}
		});

	$urlRouterProvider.otherwise('/');
};
