import { IComponentOptions } from 'angular';
import { WizardController } from './wizard.controller';

export const WizardComponent = {
	bindings: {
		onComplete: '&onComplete',
		onEnd: '&onEnd',
		onRevisit: '&onRevisit',
		onStart: '&onStart',
		views: '=views'
	},
	controller: WizardController,
	template: require('./wizard.component.html')
} as IComponentOptions;
