import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as angulartics from 'angulartics';

import { braintreeModule } from 'go-modules/braintree';
import { PayWithCardComponent } from './pay-with-card.component';

import './style.less';

export const payWithCardModule = angular.module('payWithCard', [
	angularTranslate,
	braintreeModule,
	angulartics as any
])
	.component('payWithCard', PayWithCardComponent)
	.name;
