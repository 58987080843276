import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';

import { userAccountModel } from '../../models/user-account';

import { linkUserModal } from './modal.factory';

import './modal.less';
import { LinkUserModalController } from './modal.controller';

export const linkUserModalModule = angular.module('modal.link-user', [
	goModalBootstrapModule,
	angularTranslate,
	userAccountModel
])
	.factory('linkUserModal', linkUserModal)
	.controller('LinkUserModalController', LinkUserModalController)
	.name;
