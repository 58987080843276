import template from './pay-with-card.component.html';
import { PayWithCardController as controller } from './pay-with-card.controller';

export const PayWithCardComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		formLoaded: '&'
	},
	require: {
		formController: '^form'
	}
};
