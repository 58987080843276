import * as angular from 'angular';
import * as angulartics from 'angulartics';

import * as angularTranslate from 'angular-translate';
import { SidePanelModule } from '../side-panel';
import { goModal } from '../modals';
import { confirmModalModule } from '../modals/confirm';
import { groupDepModel } from '../models/group-dep';

import { PaymentPanel } from './payment-panel.service';
import { enterInformationModule } from './enter-information';
import { reviewPaymentModule } from './review-payment';
import { groupServiceModule } from '../services/group';
import { paymentCompleteModule } from './payment-complete';
import { messageModalModule } from '../modals/message';

import './style.less';
import { transactionModelModule } from 'go-modules/models/transaction';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';
import { ngxSelfPayServiceModule } from 'ngx/go-modules/src/services/self-pay';
import { ngxLicenseServiceModule } from 'ngx/go-modules/src/services/license';
import { ngxPurchaseTypeServiceModule } from 'ngx/go-modules/src/services/purchase-type';
import { ngxPHPMathRoundModule } from 'ngx/go-modules/src/services/php-math-round';
import { SelectedModule } from 'go-modules/services/selected';
import { ngxPayWithCodeModule } from 'ngx/go-modules/src/components/payment-panel/pay-with-code';

export const paymentPanelModule = angular.module('payment-panel', [
	angularTranslate,
	SidePanelModule,
	groupDepModel,
	confirmModalModule,
	enterInformationModule,
	reviewPaymentModule,
	paymentCompleteModule,
	groupServiceModule,
	messageModalModule,
	goModal,
	angulartics as any,
	transactionModelModule,
	ngxGroupServiceModule,
	ngxSelfPayServiceModule,
	ngxLicenseServiceModule,
	ngxPurchaseTypeServiceModule,
	ngxPHPMathRoundModule,
	SelectedModule,
	ngxPayWithCodeModule
])
	.service(PaymentPanel.NG1_INJECTION_NAME, PaymentPanel);
