import { Component, OnInit } from '@angular/core';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';

@Component({
	selector: 'invalid-activity-type-change-error-renderer',
	template: require('./invalid-activity-type-change-error.component.html'),
	styles: [require('./invalid-activity-type-change-error.component.scss')]
})
export class InvalidActivityTypeChangeErrorComponent implements OnInit {
	public contactSupport: string;

	public ngOnInit (): void {
		this.contactSupport = helpUrlsLookup.contactSupport;
	}
}
