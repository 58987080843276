import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ToolUnavailableErrorComponent } from 'ngx/lti/src/app-error/tool-unavailable-error/tool-unavailable-error.component';
import {
	ActivityNotFoundErrorComponent
} from 'ngx/lti/src/app-error/activity-not-found-error/activity-not-found-error.component';
import {
	InvalidActivityTypeChangeErrorComponent
} from 'ngx/lti/src/app-error/invalid-activity-type-change-error/invalid-activity-type-change-error.component';
import {
	CourseLicenseExpiredErrorComponent
} from 'ngx/lti/src/app-error/course-license-expired-error/course-license-expired-error.component';
import {
	MissingDeploymentErrorComponent
} from 'ngx/lti/src/app-error/missing-deployment-error/missing-deployment-error.component';
import {
	MissingParametersErrorComponent
} from 'ngx/lti/src/app-error/missing-parameters-error/missing-parameters-error.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ToolUnavailableErrorComponent,
		ActivityNotFoundErrorComponent,
		InvalidActivityTypeChangeErrorComponent,
		CourseLicenseExpiredErrorComponent,
		MissingDeploymentErrorComponent,
		MissingParametersErrorComponent
	],
	entryComponents: [
		ToolUnavailableErrorComponent,
		ActivityNotFoundErrorComponent,
		InvalidActivityTypeChangeErrorComponent,
		CourseLicenseExpiredErrorComponent,
		MissingDeploymentErrorComponent,
		MissingParametersErrorComponent
	],
	exports: [
		ToolUnavailableErrorComponent,
		ActivityNotFoundErrorComponent,
		InvalidActivityTypeChangeErrorComponent,
		CourseLicenseExpiredErrorComponent,
		MissingDeploymentErrorComponent,
		MissingParametersErrorComponent
	]
})
export class AppErrorModule {}

