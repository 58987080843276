import * as angular from 'angular';
import {values} from 'lodash';
import { ILocationService } from 'angular';
import { watchForSettingChanges } from 'go-modules/models/common/client.settings';
import { PubnubService } from '../../go-modules/src/go-pubnub/pubnub.service';
import { AnalyticsService } from 'go-modules/services/analytics/analytics.service';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { FineUploaderService } from 'go-modules/fine-uploader/fine-uploader.service';
import { BannerNotificationsService } from 'go-modules/services/banner-notifications/banner-notifications.service';
import { WatsonUsageTrackingService } from 'go-modules/services/watson-usage-tracking/watson-usage-tracking.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { UserService } from 'go-modules/models/user/user.service';
import { States } from 'go-modules/enums/states.enum';
import { Transition } from '@uirouter/angularjs';
import { Observable, Observer, take } from 'rxjs';

/* @ngInject */
export function initPubnub (appData, pubnub: PubnubService) {
	pubnub.configure(appData.settings.pubnub);
}

/* @ngInject */
export function initFineUploader (appData, fineUploader: FineUploaderService) {
	fineUploader.configure(appData.settings.fineUploader);
}

/* @ngInject */
export function initFullstory (appData, User, fullstoryService: FullstoryService, userService: UserService) {
	try {
		const user = User.model(appData.currentUser);
		fullstoryService.identify(user);
		userService.setCurrentUser(user);
	} catch (e) {} // Do nothing
}

/* @ngInject */
export function initGoogleAnalytics (appData, $analytics, analyticsService: AnalyticsService) {
	const analyticsConfig = {
		analytics: appData.googleAnalytics,
		user: appData.currentUser
	};

	analyticsService.configure(analyticsConfig);

	try {
		const role = appData.userGroup.hasInstructorRole(true) ? 'instructor' : 'student';
		$analytics.eventTrack(`${role} login`, {
			category: 'login'
		});
	} catch (e) {} // Do nothing
}

/* @ngInject */
export function initBannerNotifications (bannerNotificationsService: BannerNotificationsService) {
	bannerNotificationsService.configure();
}

/* @ngInject */
export function animationsRunBlock ($rootScope) {
	// eslint-disable-next-line angular/on-watch
	$rootScope.$on('$routeChangeStart', (_evt, route, prevRoute) => {
		const body = angular.element(document.body);
		body.removeClass('animate-from-left');
		body.removeClass('animate-from-right');
		body.removeClass('slide');
		if (prevRoute && route.animate === prevRoute.animate && route.params.direction) {
			body.addClass(route.animate);
			body.addClass('animate-from-' + route.params.direction);
		}
	});
}

/* @ngInject */
export function initializeWizardRunBlock (
	$location: ILocationService,
	appData: any,
	$state,
	$interval: ng.IIntervalService
) {

	// Start up settings.js observation
	watchForSettingChanges(1000 * 60 * 15, $interval);

	const environmentVarsService = EnvironmentVarsService.getInstance();
	environmentVarsService.reset();
	// Setup environmental variables
	environmentVarsService.set(EnvironmentVarsService.VAR.ENVIRONMENT, {
		mode: appData.hasCourseLevelLTI ? LTI_ENVIRONMENT_MODES.COURSE_LEVEL : LTI_ENVIRONMENT_MODES.DEFAULT,
		name: ENVIRONMENTS.LTI,
		version: appData.lti_version
	});
	environmentVarsService.set(EnvironmentVarsService.VAR.WHITELABEL, appData.whitelabel);
	environmentVarsService.set(EnvironmentVarsService.VAR.DISABLE_ACTIVITY_EDITING, appData.disableActivityEditing);
	environmentVarsService.set(EnvironmentVarsService.VAR.READONLY, appData.readonly);
	environmentVarsService.set(EnvironmentVarsService.VAR.USER_PUBNUB_CHANNEL, appData.user_pubnub_channel);
	environmentVarsService.set(EnvironmentVarsService.VAR.CUSTOM_PARAMS, appData.customParams);
	environmentVarsService.set(EnvironmentVarsService.VAR.FOLLOW_LTI_RETURN_URL, appData.lti.followLtiReturnUrl);
	environmentVarsService.set(
		EnvironmentVarsService.VAR.LAUNCH_PRESENTATION_RETURN_URL,
		appData.lti.launchPresentationReturnUrl
	);

	// Setup extra session data environmental variable for LTI grade passback.
	// The session creation wizard will read from this and use it when creating a session.
	if (appData.lti.sourceId && appData.lti.outcomeUrl) {
		environmentVarsService.set(EnvironmentVarsService.VAR.EXTRA_SESSION_DATA, {
			oauthConsumerKey: appData.lti.oauthConsumerKey,
			outcomeUrl: appData.lti.outcomeUrl,
			scoreFormat: appData.lti.scoreFormat,
			sourceId: appData.lti.sourceId
		});
	}

	// Navigate to wizard setup when there are some prompts
	// We replace the current URL with the new one since some browsers see /lti/v1
	// as it's first page and not the wizard page. Causes undesirable actions when using
	// the back button during the wizard setup

	if (values(appData.prompts).indexOf(true) !== -1) {
		$state.go($state.go(States.DASHBOARD_WIZARD, appData.prompts));
	} else if($location.path() === '/' || $location.path() === '') {
		if (appData.hasCourseLevelLTI) {
			$state.go(States.DASHBOARD_FOLDER_VIEW, {folder_id: appData.group_id});
		} else {
			$state.go(States.DASHBOARD_ACTIVITY_VIEW, {activity_id: appData.activity.activity_id});
		}
	}

	// Clear data for new user
	sessionStorage.setItem('recent-feedback-types', null);
}


/* @ngInject */
export const addUnsavedTranscriptionUsage = (watsonUsageTrackingService: WatsonUsageTrackingService) => {
	watsonUsageTrackingService.savePendingTranscriptionUsage();
};

/**
 * Remove CurrentFilter if page loaded outside the session review page
 */
/* @ngInject */
export const removeCurrentFilter = ($transitions: Transition, $window, $state) => {
	new Observable((subscriber: Observer<any>) => {
		$transitions.onSuccess({}, (transition): void => {
			subscriber.next(transition);
		});
	}).pipe(take(1))
		.subscribe(() => {
			if (!$state.is(States.DASHBOARD_SESSION_VIEW)) $window.sessionStorage.removeItem('currentFilter');
		});
};

