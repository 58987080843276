import template from './address-details-form.component.html';
import { AddressDetailsFormController as controller } from './address-details-form.controller';

export const AddressDetailsFormComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		onAddressChange: '&'
	},
	require: {
		formController: '^form'
	}
};
