/* @ngInject */
export function WizardFactory ($q, EventChannel) {

	function Wizard (views) {
		if (!Array.isArray(views)) {
			throw new Error('Wizard requires views to be an Array');
		} else if (!views.length) {
			throw new Error('Wizard requires there to be at least one view');
		}

		const deferred = $q.defer(),
			eventChannel = new EventChannel();

		this.currentView = false;
		this.views = views || [];
		this.result = deferred.promise;

		this.hasNext = () => {
			const index = this.views.indexOf(this.currentView);
			return index + 1 < this.views.length;
		};

		this.next = () => {

			// Finish if there is no next
			if (!this.hasNext()) {
				this.finish();
				return;
			}

			if (this.currentView) {
				eventChannel.broadcast('onEnd', this.currentView);
			}

			if (this.hasNext()) {
				let index = this.views.indexOf(this.currentView);
				this.currentView = this.views[++index];
				eventChannel.broadcast('onStart', this.currentView);
			}

			return this;
		};

		this.hasPrev = () => {
			const index = this.views.indexOf(this.currentView);
			return index > 0;
		};

		this.prev = () => {

			if (this.hasPrev()) {
				let index = this.views.indexOf(this.currentView);
				this.currentView = this.views[--index];
				eventChannel.broadcast('onRevisit', this.currentView);
				eventChannel.broadcast('onStart', this.currentView);
			}

			return this;
		};

		this.finish = () => {

			if (this.currentView) {
				eventChannel.broadcast('onEnd', this.currentView);
			}

			this.currentView = false;
			deferred.resolve();

			return this;
		};

		this.getPrevView = () => {
			let prevView = false;
			let index = this.views.indexOf(this.currentView);
			if (index > 0) {
				prevView = this.views[--index];
			}

			return prevView;
		};

		this.onStart = (callback) => {
			eventChannel.subscribe('onStart', callback);
		};

		this.onEnd = (callback) => {
			eventChannel.subscribe('onEnd', callback);
		};

		this.onRevisit = (callback) => {
			eventChannel.subscribe('onRevisit', callback);
		};

		this.onRevisit((view) => {
			if (typeof view.onRevisit === 'function') {
				view.onRevisit.call(this, view);
			}
		});

		this.on = eventChannel.subscribe.bind(eventChannel);
		this.trigger = eventChannel.broadcast.bind(eventChannel);
		this.off = eventChannel.unsubscribe.bind(eventChannel);
	}

	return Wizard;
}
