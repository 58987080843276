import * as angular from 'angular';
import * as $ocLazyLoad from 'oclazyload';
import { AccountSetupWizardViewComponent } from './account-setup/account-setup-wizard-view.component';
import { ActivityEditorWizardViewComponent } from './activity-editor-wizard-view/activity-editor-wizard-view.component';
import { CoursePaymentWizardViewComponent } from './course-payment/course-payment-wizard-view.component';
import { CourseSetupWizardViewComponent } from './course-setup/course-setup-wizard-view.component';
import { QuickStartGuideWizardViewComponent } from './quick-start-guide/quick-start-guide-wizard-view.component';
import { appDataModule } from '../../app-data';
import { coursePaymentWallModule } from 'go-modules/course-payment-wall';
import { courseEditorModule } from 'go-modules/course-editor';
import { goPubnubModule } from 'go-modules/go-pubnub';
import { richDropdownModule } from 'go-modules/rich-dropdown';
import { activityEditorModule } from 'go-modules/activity-editor';
import { lazyPaymentPanelModule } from 'go-modules/payment-panel/lazy-index';
import { featureFlagModule } from 'go-modules/feature-flag';
import { copyPreviousAssignmentModalModule } from 'go-modules/modals/copy-previous-assignment';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';
import { ChoosePlanWizardViewComponent } from './choose-plan/choose-plan-wizard-view.component';
import { LicensePaymentWizardViewComponent } from './license-payment/license-payment-wizard-view.component';
import { paymentPanelModule } from 'go-modules/payment-panel';
import { ngxWizardViewModule } from 'ngx/lti/src/wizards';

export const wizardViewModule = angular
	.module('wizard-view', [
		appDataModule.name,
		courseEditorModule,
		featureFlagModule,
		coursePaymentWallModule,
		goPubnubModule,
		richDropdownModule,
		$ocLazyLoad,
		paymentPanelModule.name,
		lazyPaymentPanelModule,
		activityEditorModule.name,
		copyPreviousAssignmentModalModule,
		fullstoryModule,
		ngxGroupServiceModule,
		ngxWizardViewModule
	])
	.component('accountSetupWizardView', AccountSetupWizardViewComponent)
	.component('activityEditorWizardView', ActivityEditorWizardViewComponent)
	.component('coursePaymentWizardView', CoursePaymentWizardViewComponent)
	.component('courseSetupWizardView', CourseSetupWizardViewComponent)
	.component('quickStartGuideWizardView', QuickStartGuideWizardViewComponent)
	.component('choosePlanWizardView', ChoosePlanWizardViewComponent)
	.component('licensePaymentWizardView', LicensePaymentWizardViewComponent)
	.name;
