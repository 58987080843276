import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { FormValidationService } from 'go-modules/form-validation/form-validation.service';
import type { Product } from 'go-modules/services/group/product';
import type { NgxSelfPayService } from 'ngx/go-modules/src/services/self-pay';
import { forkJoin, iif, map, Observable, of, retry, switchMap } from 'rxjs';
import { MessageModal } from 'go-modules/modals/message/modal.factory';
import { PAYMENT_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import { NgxLicenseService } from 'ngx/go-modules/src/services/license';
import { NgxPurchaseTypeService } from 'ngx/go-modules/src/services/purchase-type/purchase-type.service';
import { NgxPHPMathRoundService } from 'ngx/go-modules/src/services/php-math-round/php-math-round.service';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { ENVIRONMENT_CONSTANTS } from 'go-modules/constants/environment-constants.constant';
import { NgxAccountService } from 'ngx/go-modules/src/services/account';
import { Account } from 'ngx/go-modules/src/interfaces/account';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Bindings {
	products: Product[];
	options: any;
}
interface ProrateDates {
	start: string;
	end: string;
}

interface ProductSelectedOption {
	title: string;
	selectedTitle: string;
	description: string;
	option: Product;
}

export interface LicenseDetailsForm {
	selectedOrg: any;
	selectedLicenseType: any;
	selectedProduct: Product;
	licenseName: any;
	seats: number;
	additional_seats?: number;
	qty: number;
	subtotal: string;
	licenseStartDate: Date;
	licenseEndDate: Date;
	pricePerSeat: string;
	prorateAdjustment: any;
	prorate: number;
}

export class LicenseDetailsFormController implements Bindings {
	private static readonly numberSeatsStartDiscount: number = 100;
	private static readonly rejectButtonSource = 'reject';

	//bindings
	public products: Product[];
	public options;
	public formLoaded: () => void;

	public orgs = [];
	public productsSelection = [];

	public licenseDetailsForm: LicenseDetailsForm;

	public formValidationService;
	public formController;
	public orgRequestError = false;
	public orgRequestLoaded = false;
	public controllerInitialized = false;
	public seatDiscountModalHasOpened: boolean = false;
	public orgSelected = null;
	public productSelected: Product = null;
	public productSelectedOption: ProductSelectedOption;
	public seatsDiscountMsg = '';
	public PAYMENT_TYPES = PAYMENT_TYPES;
	public prorateDates: ProrateDates;

	/* @ngInject */
	constructor (
		public ngxPurchaseTypeService: NgxPurchaseTypeService,
		public ngxPHPMathRoundService: NgxPHPMathRoundService,
		private $scope: ng.IScope,
		private $translate: ng.translate.ITranslateService,
		private UseTypeModel,
		private ngxSelfPayService: NgxSelfPayService,
		private messageModal: MessageModal,
		private $window: ng.IWindowService,
		private $filter: ng.IFilterService,
		private ngxLicenseService: NgxLicenseService,
		private ngxAccountService: NgxAccountService,
		private ngxDowngradeModalService: DowngradeModalService,
		private ngxGoToastService: NgxGoToastService,
		private featureFlag: FeatureFlag
	) {
	}

	public get licensesProductNumSeats (): number | null {
		return this.licenseDetailsForm.selectedProduct?.licenseProduct?.num_seats ?? null;
	}

	public get showSeatsAndPrice () {
		return this.products
			&& this.products.length
			&& (this.licensesProductNumSeats === null || this.licensesProductNumSeats > 1);
	}

	public $onInit () {
		// make sure it only initialize once
		if (!this.controllerInitialized) {
			this.setupForm();
			this.buildUserOrganizations();
			this.getDefaultLicenseName();
			this.getLicenseProducts();
			this.setLicenseDatesAndSeatsValue();
			this.formValidationService = new FormValidationService(this.formController);
			this.seatsDiscountMsg = this.$translate.instant('payment-panel-license-details_license-seats-discount-message', {
				numberSeatsStartDiscount: LicenseDetailsFormController.numberSeatsStartDiscount
			});

			this.controllerInitialized = true;
		}
	}

	public $onChanges (changes) {
		//Unit test run onChanges before onInit
		if (!this.controllerInitialized) {
			// STAB-1473
			this.$onInit();
		}

		if (changes.products?.currentValue?.length) {
			this.initializeProductSelection(changes.products.currentValue);
		}
	}

	public setSelectedProduct (productOption) {
		this.licenseDetailsForm.selectedProduct = productOption;

		this.setProductSelectedOption(productOption);
		this.setPricePerSeat();
		this.calculateSubtotal();
	}

	public calculateSubtotal () {
		const seats = this.getSeatsWithOnlyNumericCharacters();

		if (!this.ngxPurchaseTypeService.isLicenseSeatsPurchase(this.options)) {
			this.licenseDetailsForm.seats = seats;
		} else {
			this.licenseDetailsForm.additional_seats = seats;
		}

		this.licenseDetailsForm.qty = seats;

		if (!this.licenseDetailsForm.selectedProduct || !seats) {
			this.licenseDetailsForm.subtotal = '0.00';
			return;
		}


		// If not license upgrade set current product price to always be a 0 if we pass a product in the option
		// because we will get 0 subtotal for non license upgrade purchase
		const currentProductPrice = this.ngxPurchaseTypeService
			.isLicenseUpgradePurchase(this.options) ?
			parseFloat(this.options.license.license.licenseProduct.product.price) : 0;
		const unProratedSubtotal = this.round(
			(parseFloat(this.licenseDetailsForm.selectedProduct.price) * seats) -
			(currentProductPrice * seats));

		this.licenseDetailsForm.prorate = this.calculateProrate();

		const creditLineSubtotal = this.round(
			(this.licenseDetailsForm.qty * -1) *
			currentProductPrice *
			this.licenseDetailsForm.prorate
		);

		const debitLineSubtotal = this.round(
			this.licenseDetailsForm.qty *
			+this.licenseDetailsForm.selectedProduct.price *
			this.licenseDetailsForm.prorate
		);

		this.licenseDetailsForm.subtotal = this.ngxPHPMathRoundService.round(
			(debitLineSubtotal) + (creditLineSubtotal),
			2
		);

		this.licenseDetailsForm.prorateAdjustment = this.ngxPHPMathRoundService.round(
			Math.abs(parseFloat(this.licenseDetailsForm.subtotal) - unProratedSubtotal),
			2
		);

		if (this.licenseDetailsForm.prorateAdjustment === '0.00') {
			this.licenseDetailsForm.prorateAdjustment = null;
			this.licenseDetailsForm.prorate = null;
		}
	}

	public readOnlyDates () {
		return this.ngxPurchaseTypeService.isLicenseRenewal(this.options) ||
		this.ngxPurchaseTypeService.isLicenseModificationPurchase(this.options);
	}

	public canViewPlans (): boolean {
		return (this.ngxPurchaseTypeService.isLicenseUpgradePurchase(this.options) ||
			this.ngxPurchaseTypeService.isLicenseInitialPurchase(this.options) ||
			this.ngxPurchaseTypeService.isLicenseRenewal(this.options)) &&
			this.productSelectedOption?.option &&
			this.featureFlag.isAvailable('LICENSE_UPGRADE_PURCHASE');
	}

	public viewPlans () {
		this.ngxDowngradeModalService.openCompareProductsDialog({
			transactionType: this.options.licenseTransactionType,
			license: this.options.license,
			products: this.products
		}).afterClosed().subscribe((selectedProduct) => {
			if (selectedProduct) {
				this.setSelectedProduct(selectedProduct);
				// make sure changes get reflected in LTI
				this.safeDigest();
			}
		});
	}

	public showDiscountsModal () {
		let seats = this.getSeatsWithOnlyNumericCharacters();
		if (this.options.license) {
			seats += this.options.license.total_seats;
		}

		if (seats >= LicenseDetailsFormController.numberSeatsStartDiscount &&
			!this.seatDiscountModalHasOpened) {
			this.messageModal.open({
				name: 'seat-discount-modal',
				modalData: {
					title: this.$translate.instant('payment-panel-payment-seat_discount_modal-title'),
					message: this.seatsDiscountMsg,
					resolveBtnText: this.$translate.instant('common_continue'),
					resolveBtnClass: 'primary-btn',
					rejectBtnClass: 'tertiary-btn',
					rejectBtnText: this.$translate.instant('payment-panel-payment-seat_discount_modal-contact-sales')
				}
			}).result
				.catch((source: string) => {
					if (source === LicenseDetailsFormController.rejectButtonSource) {
						this.$window.open('https://get.goreact.com/pricing/quote/', '_blank');
					}
				})
				.finally(() => this.seatDiscountModalHasOpened = true);
		}
	}

	public switchOrg () {
		const products$ = this.ngxSelfPayService.getProducts(this.licenseDetailsForm.selectedOrg.org_id);

		// Fetch the correct account if the current account's parent is not the currently selected org
		let userAccount$: Observable<Account>;
		if (this.options.group?.parent_id !== this.licenseDetailsForm.selectedOrg.group_id) {
			userAccount$ = this.ngxAccountService.getOrCreateUserAccount(
				this.options.user.user_id, this.licenseDetailsForm.selectedOrg.group_id
			);
		} else {
			userAccount$ = of(this.options.group);
		}

		forkJoin([products$, userAccount$]).subscribe({
			next: ([products, accountResponse]) => {
				this.options.group = accountResponse;
				this.initializeProductSelection(products);
			},
			error: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'common_unknown-error_message'
				});
			}
		});

		// Clear seats, start date, and end date fields
		this.licenseDetailsForm.licenseStartDate = null;
		this.licenseDetailsForm.licenseEndDate = null;
		this.licenseDetailsForm.seats = null;
	}

	public getLicenseProducts () {
		if (!this.options.license) return;
		if (this.options.product && !this.options.product.licenseProduct) throw Error('The options.product should have licenseProduct');

		const productObservable = iif(
			() => this.options.product != null,
			of(this.options.product),
			this.ngxLicenseService.licenseProduct(this.options.license.license_id)
		);

		productObservable
			.pipe(switchMap((product) => {
				/**
				 * If seat purchase, there is no need to get all the product so we need to fallback here
				 */
				if (this.ngxPurchaseTypeService.isLicenseSeatsPurchase(this.options)) {
					this.productSelected = product;
					return of([product]);
				}

				return this.ngxSelfPayService.getProducts(this.options.group.org_id)
					.pipe(map((products) => {
						/**
						 * Pre-select product associated to the license/product in the options
						 */
						if (products.some((curProduct) => curProduct.product_id === product?.product_id)) {
							this.productSelected = product;
						}

						if (this.ngxPurchaseTypeService.isLicenseUpgradePurchase(this.options)) {
							// unlike the compare products dialog, do not include the current license product
							products = products.filter((itemProduct) => {
								// only include products that cost more and are on a different tier than the
								// license's current product
								return parseFloat(itemProduct.price) >
									parseFloat(this.options.license.license.licenseProduct.product.price) &&
									itemProduct.licenseProduct.tier_name !==
									this.options.license.license.licenseProduct.tier_name;
							});
						}

						return products;
					}));
			})).subscribe((products: Product[]) => {
				this.initializeProductSelection(products);
			});
	}

	public shouldShowProrate (): boolean {
		return this.licenseDetailsForm.prorateAdjustment != null;
	}

	public shouldShowEuMessage (): boolean {
		return this.hasAiFeatures() && ENVIRONMENT_CONSTANTS.APP_REGION === 'EU';
	}

	public getSubtotalProrateMessageTranslationKey () {
		if (this.shouldShowEuMessage()) {
			return 'payment-panel-license-details_prorated-note-eu';
		}
		return 'payment-panel-license-details_prorated-note';
	}

	private hasAiFeatures (): boolean {
		return this.licenseDetailsForm.selectedProduct?.licenseProduct?.transcriptions_enabled ||
			this.licenseDetailsForm.selectedProduct?.licenseProduct?.ai_prompts_enabled;
	}

	private setProductSelectedOption (product: Product) {
		this.productSelectedOption = {
			title: product.display_name,
			selectedTitle: product.display_name,
			description: product.description,
			option: product
		};
	}

	private calculateProrate () {
		if (!this.ngxPurchaseTypeService.isLicenseModificationPurchase(this.options)) {
			return 1;
		}

		/** From Jonathan:
		 * The problem is that the backend is already sending back utc time, but dayjs()
		 * thinks it's local time. When we parse it with dayjs().utc() it doubles the offset.
		 * The additional problem is that Lovell overrode the date serialization to not have a timezone 5 years ago.
		 * TODO: Will create a ticket carbon serializaation
		 */
		const today = dayjs();
		const currentAccessDate = dayjs.utc(this.options.license.license.current_access_date);
		const endDate = dayjs.utc(this.options.license.license.ends_at);
		const totalNumberOfDays = endDate.diff(currentAccessDate, 'day');
		const remainingDays = endDate.add(1, 'day').diff(today, 'day');

		this.prorateDates = {
			start: today.format('MMM D'),
			end: endDate.format('MMM D')
		};
		return Math.min(1.0, Math.max(remainingDays/totalNumberOfDays, 0.0));
	}

	private setPricePerSeat () {
		let pricePerSeatValue = '0.00';

		if (this.licenseDetailsForm.selectedProduct) {
			pricePerSeatValue = this.licenseDetailsForm.selectedProduct.price;
		}

		if (this.ngxPurchaseTypeService.isLicenseUpgradePurchase(this.options)) {
			pricePerSeatValue = this.ngxPHPMathRoundService.round(
				parseFloat(this.licenseDetailsForm.selectedProduct.price) -
				this.options.license.license.licenseProduct.product.price,
				2
			);
		}

		this.licenseDetailsForm.pricePerSeat = this.$translate.instant('payment-panel-license-details_license-price-per-seat-value', {
			pricePerSeat: this.$filter('number')((pricePerSeatValue), 2)
		});
	}

	private getSeatsWithOnlyNumericCharacters () {
		let seats: string | number = '';

		if (this.ngxPurchaseTypeService.isLicenseSeatsPurchase(this.options)) {
			seats = this.licenseDetailsForm.additional_seats ?? '';
		} else {
			seats = this.licensesProductNumSeats ?? this.licenseDetailsForm.seats ?? '';
		}

		// Remove decimal and non-numeric characters
		seats = seats.toString().split('.')[0];
		seats = seats.replace(/\D/g, '');
		seats = parseInt(seats, 10);

		return isNaN(seats) ? null : seats;
	}

	private initializeProductSelection (products: Product[]) {
		this.products = products;
		this.productsSelection = products.map((option: Product) => {
			const title = option.display_name;
			const description =  option.description;
			return {
				option,
				title,
				selectedTitle: title,
				description
			};
		});

		if (this.options.selectedProduct) {
			this.productSelected = this.options.selectedProduct;
		}

		if (this.productSelected) {
			const isAvailableLicenseProduct = this.products.some((product) =>
				product.product_id === this.productSelected.product_id);
			// if selected product is not available to this org,
			// select a different product with the same tier
			if (!isAvailableLicenseProduct && this.licenseDetailsForm.selectedProduct?.licenseProduct) {
				this.productSelected = products.find((product) => {
					return product.licenseProduct.tier_name ===
						this.licenseDetailsForm.selectedProduct.licenseProduct.tier_name;
				});
			}
		}

		this.licenseDetailsForm.selectedProduct = this.productSelected ?? this.productsSelection[0].option;

		this.setProductSelectedOption(this.licenseDetailsForm.selectedProduct);
		this.calculateSubtotal();
		this.setPricePerSeat();
	}

	private setupForm () {
		if (!this.licenseDetailsForm) {
			this.licenseDetailsForm = {
				seats: null,
				additional_seats: null,
				qty: null,
				licenseName: '',
				selectedOrg: null,
				selectedLicenseType: null,
				selectedProduct: null,
				licenseStartDate: null,
				licenseEndDate: null,
				subtotal: '0.00',
				pricePerSeat: '0.00',
				prorateAdjustment: null,
				prorate: null
			};
		} else {
			this.orgSelected = this.licenseDetailsForm.selectedOrg;
			this.productSelected = this.licenseDetailsForm.selectedProduct;

			this.licenseDetailsForm.selectedOrg = null;
			this.licenseDetailsForm.selectedProduct = null;
		}

		if (this.options.product) {
			this.productSelected = this.options.product;
		}
	}

	private setLicenseDatesAndSeatsValue () {
		if (this.options.license?.license) {
			const readOnlyDates = this.ngxPurchaseTypeService.isLicenseModificationPurchase(this.options);
			this.licenseDetailsForm.licenseStartDate = readOnlyDates
				? dayjs(this.options.license.license.current_access_date).toDate()
				: dayjs().toDate();

			if (readOnlyDates) {
				this.licenseDetailsForm.licenseEndDate = dayjs(this.options.license.license.ends_at).toDate();
			} else {
				let startDate = dayjs().toDate();
				if (dayjs(this.options.license.license.ends_at).isAfter(startDate)) {
					startDate = this.options.license.license.ends_at;
				}
				if (this.options.license.license.licenseProduct) {
					this.licenseDetailsForm.licenseEndDate = this.getEndDate(
						startDate,
						this.options.license.license.licenseProduct
					);
				}
				this.$scope.$watch('[$ctrl.licenseDetailsForm.selectedProduct]', ([selectedProduct]: [Product]) => {
					if (selectedProduct) {
						this.licenseDetailsForm.licenseEndDate = this.getEndDate(
							startDate,
							selectedProduct.licenseProduct
						);
					}
				});
			}
			this.licenseDetailsForm.seats = this.options.license.total_seats;
			if (this.options.license.total_seats_consumed > this.licenseDetailsForm.seats) {
				this.licenseDetailsForm.seats = this.options.license.total_seats_consumed;
			}
		} else {
			this.$scope.$watch('[$ctrl.licenseDetailsForm.licenseStartDate, $ctrl.licenseDetailsForm.selectedProduct]', ([licenseStartDate, selectedProduct]: [Date, Product]) => {
				if (licenseStartDate && selectedProduct) {
					this.licenseDetailsForm.licenseEndDate = this.getEndDate(
						licenseStartDate,
						selectedProduct.licenseProduct
					);
				}
			});
		}
	}

	private getEndDate (startDate, licenseProduct) {
		const timeZone = dayjs.tz.guess();
		const date = dayjs.tz(startDate, timeZone);
		return date.add(licenseProduct.duration_months, 'month').tz(timeZone).toDate();
	}

	private getDefaultLicenseName () {
		if (this.licenseDetailsForm?.licenseName) {
			return;
		}

		if (this.options.license) {
			this.licenseDetailsForm.licenseName = this.options.license.name;
		} else {
			const date = new Date();
			const defaultUseType = this.UseTypeModel
				.TYPES.OTHER.charAt(0).toUpperCase() + this.UseTypeModel.TYPES.OTHER.slice(1);
			const useType = this.options.group?.use_type?.name ?? defaultUseType;
			const name = this.options.group?.name ?? this.options.user.fullname;
			this.licenseDetailsForm.licenseName = `${name} ${useType} ${date.getFullYear()}`;
		}
	}

	private buildUserOrganizations () {
		if (this.options.license) {
			this.orgRequestLoaded = true;
			this.formLoaded();
			return;
		}

		const triggerSwitchOrg = !this.controllerInitialized &&
			this.ngxPurchaseTypeService.isLicenseInitialPurchase(this.options) &&
			!this.ngxPurchaseTypeService.isLicenseRenewal(this.options);

		this.ngxSelfPayService
			.getUserOrgsEligibleForSelfpay(this.options.user.user_id)
			.pipe(retry(3))
			.subscribe({
				next: (groups: any[]) => {
					this.orgs = groups;
					// Set account org as selected org default value
					if (this.options.group) {
						// Account org should be the first in the list
						this.orgs = this.orgs.sort((group) =>
							group.group_id === +this.options.group.getParentId() ? -1 : 1
						);

						// Select account org by default
						this.licenseDetailsForm.selectedOrg = this.orgs
							.find((org) =>
								org.group_id === (this.orgSelected?.group_id ?? +this.options.group.getParentId()));
					} else {
						// User does not have an account select the first available org.
						this.licenseDetailsForm.selectedOrg = this.orgSelected ?? this.orgs[0];
					}
					this.orgRequestLoaded = true;
					this.orgRequestError = false;

					if (triggerSwitchOrg) {
						this.switchOrg();
					}

					this.formLoaded();
				},
				error: () => {
					this.orgRequestLoaded = true;
					this.orgRequestError = true;
				}
			});
	}

	public round (value: number) {
		return parseFloat(this.ngxPHPMathRoundService.round(value, 2));
	}

	private safeDigest () {
		// eslint-disable-next-line angular/no-private-call
		const phase = this.$scope.$root.$$phase || this.$scope.$$phase;
		if (!phase) {
			this.$scope.$digest();
		}
	}
}
