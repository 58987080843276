import template from './payment-complete.component.html';
import { PaymentCompleteController as controller } from './payment-complete.controller';

export const PaymentCompleteComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		paymentMethod: '<',
		product: '<',
		transaction: '<',
		group: '<',
		options: '<',
		licenseDetails: '<'
	}
};
