import * as angular from 'angular';
import { appDataModule } from 'lti/app-data';
import { AppRootComponent } from './app.component';
import { userModel } from 'go-modules/models/user';
import { groupDepModel } from 'go-modules/models/group-dep';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';
import { sessionManager } from 'go-modules/session-manager';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { SelectedModule } from 'go-modules/services/selected';
import * as $ocLazyLoad from 'oclazyload';
import { featureFlagModule } from 'go-modules/feature-flag';
import { libraryCollectionViewerModalModule } from 'go-modules/modals/library-collection-viewer';
import { ngxGoSidepanelServiceModule } from 'ngx/go-modules/src/services/go-sidepanel';
import { GoLocationModule } from 'go-modules/services/go-location';
import { goBannerModule } from 'go-modules/go-banner';
import uiRouter from '@uirouter/angularjs';
import { AppRouteViewComponent } from './app-route-view/app-route-view.component';
import { newDashboardModule } from 'ngx/go-modules/src/components/dashboard';
import { dashboardServicesModule } from 'dashboard/services';
import { uiViewInnerModule } from 'go-modules/ui-view-inner';
import { goTourModule } from 'go-modules/go-tour';
import { activityModel } from 'go-modules/models/activity';
import { sessionCreationWizardModule } from 'go-modules/session-creation-wizard';
import { sessionEditorPanelModule } from 'go-modules/session-editor-panel';

export const appRootModule = angular
	.module('app.root', [
		appDataModule.name,
		userModel,
		groupDepModel,
		$ocLazyLoad,
		libraryCollectionViewerModalModule,
		sessionManager,
		ngxGoToastModule,
		fullstoryModule,
		SelectedModule,
		featureFlagModule,
		ngxGoSidepanelServiceModule,
		GoLocationModule,
		goBannerModule,
		uiRouter,
		newDashboardModule,
		uiViewInnerModule,
		dashboardServicesModule,
		goTourModule,
		activityModel,
		sessionCreationWizardModule,
		sessionEditorPanelModule
	])
	.component('appRoot', AppRootComponent)
	.component('appRouteView', AppRouteViewComponent)
	.name;
