import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as angulartics from 'angulartics';

import { PaymentCompleteComponent } from './payment-complete.component';

import './style.less';
import { goPrintModule } from '../../go-print';

export const paymentCompleteModule = angular.module('paymentComplete', [
	angularTranslate,
	goPrintModule,
	angulartics as any
])
	.component('paymentComplete', PaymentCompleteComponent)
	.name;
