import { CountryService } from 'go-modules/country/country.service';
import type { CountryData } from 'go-modules/country/country.service';

/* @ngInject */
export class CountryPickerController {

	public countries: CountryData[];
	public selectedCountry: CountryData;
	public ngModelCtrl: ng.INgModelController;

	constructor (private Country: CountryService, private $log: ng.ILogService) {}

	public $onInit () {
		this.Country.getCountries()
			.then((countries) => {
				this.countries = countries;

				// Set US as default
				this.selectedCountry = this.countries.find((country) => country.code === 'US');
				this.onChange(this.selectedCountry);
			}).catch((error) => {
				this.$log.error(error);
			});
	}

	/**
	 * When select changes, update bound ng-model
	 * @param country
	 */
	public onChange (country: CountryData): void {
		this.ngModelCtrl.$setViewValue(country.code);
		this.ngModelCtrl.$commitViewValue();
	}
}
