import { IComponentOptions } from 'angular';
import { ChoosePlanWizardViewController } from './choose-plan-wizard-view.controller';

export const ChoosePlanWizardViewComponent = {
	controller: ChoosePlanWizardViewController,
	require: {
		wizardController: '^wizard'
	},
	template: require('./choose-plan-wizard-view.component.html')
} as IComponentOptions;
