import template from './country-picker.component.html';
import { CountryPickerController as controller } from './country-picker.controller';

export const CountryPickerComponent: ng.IComponentOptions = {
	template,
	controller,
	require: {
		ngModelCtrl: 'ngModel'
	},
	bindings: {
		id: '@pickerId',
		name: '@'
	}
};
