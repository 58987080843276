import { IComponentOptions } from 'angular';
import { CoursePaymentWizardViewController } from './course-payment-wizard-view.controller';

export const CoursePaymentWizardViewComponent = {
	bindings: {
		onError: '&',
		onSuccess: '&'
	},
	controller: CoursePaymentWizardViewController,
	require: {
		wizardController: '^wizard'
	},
	template: require('./course-payment-wizard-view.component.html')
} as IComponentOptions;
