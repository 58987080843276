import { Dropin } from 'braintree-web-drop-in';
import { Product } from 'go-modules/services/group/product';
import { PAYMENT_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import { NgxPurchaseTypeService } from 'ngx/go-modules/src/services/purchase-type/purchase-type.service';

export class EnterInformationController {

	//bindings
	public options;
	public dropinInstance: Dropin;
	public group: any;
	public products: Product[];
	public product: Product;
	public onProductChange: (value: {product: Product}) => void;
	public payFormHasLoaded: ({dropinInstance: Dropin}) => void;
	public onAddressChange: (value) => void;
	public licenseDetailsForm;

	public isLicenseFormLoaded = false;
	public isPaymentFormLoaded = false;
	public PAYMENT_TYPES = PAYMENT_TYPES;

	/* @ngInject */
	constructor (
		public $analytics: angulartics.IAnalyticsService,
		public ngxPurchaseTypeService: NgxPurchaseTypeService
	) {}

	public $onInit () {
		if (this.options.type === PAYMENT_TYPES.REQUEST_INVOICE) {
			this.isPaymentFormLoaded = true;
		}
	}

	public $onChanges (changes: ng.IOnChangesObject): void {
		if (changes.mode) {
			switch (changes.mode.currentValue) {
				case PAYMENT_TYPES.REQUEST_INVOICE:
					this.isPaymentFormLoaded = true;
					break;
				case PAYMENT_TYPES.CARD:
				default:
					this.isPaymentFormLoaded = false;
					break;
			}
		}
	}

	public selectedProductChanged (product) {
		this.onProductChange({product});
		const eventName = product.is_plan ? 'plan-product' : 'non-plan-product';
		this.$analytics.eventTrack(eventName, {
			category: 'enter-payment-information'
		});
	}

	public addressChanged (address) {
		this.onAddressChange({address});
	}

	public licenseFormLoaded () {
		this.isLicenseFormLoaded = true;
		this.formLoaded();
	}

	public paymentFormLoaded (dropinInstance) {
		this.isPaymentFormLoaded = true;
		this.dropinInstance = dropinInstance;
		this.formLoaded();
	}

	private formLoaded () {
		if ((this.isLicenseFormLoaded || !this.ngxPurchaseTypeService.isLicensePurchase(this.options)) &&
			this.dropinInstance) {
			this.payFormHasLoaded({dropinInstance: this.dropinInstance});
		}
	}
}
