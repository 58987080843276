import { Product } from 'go-modules/services/group/product';

export class ProductDetailsFormController {

	//bindings
	public group;
	public products: Product[];
	public optionProduct: Product;
	public onSelectedProductChange: (value) => void;

	public selectedProduct: Product;

	/* @ngInject */
	constructor () {}

	public $onChanges (changes) {
		if (changes.products.currentValue && Array.isArray(changes.products.currentValue)) {
			this.selectedProduct = changes.products.currentValue
				.find((product) => product.product_id === this.optionProduct?.product_id) ?? this.products[0];

			this.selectedProductChanged();
		}
	}

	public selectedProductChanged () {
		this.onSelectedProductChange({product: this.selectedProduct});
	}
}
