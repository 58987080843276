import { clientSettings } from 'go-modules/models/common/client.settings';

export interface CountryData {
	name: string;
	code: string;
}

/* @ngInject */
export class CountryService {

	constructor (
		private $http: ng.IHttpService
	) {}

	/**
	 * Get a list of countries
	 */
	public getCountries (): ng.IPromise<CountryData[]> {
		return this.$http.get(`${clientSettings.GoReactV2API}/countries`)
			.then((response: ng.IHttpResponse<CountryData[]>) => {
				return response.data;
			});
	}
}
