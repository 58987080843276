import angular from 'angular';

/* @ngInject */
export function WizardController (
	$location: ng.ILocationService,
	$timeout: ng.ITimeoutService,
	$q: ng.IQService,
	$log: ng.ILogService,
	Wizard,
	$scope
) {
	const vm = this;

	vm.$onInit = () => {
		vm.wizard = new Wizard(vm.views);

		vm.isContentLoaded = false;
		vm.isContentValid = true;

		vm.wizard.onStart((view) => {
			// TODO: For now just reset these values,
			// later we should have them attached
			// to the current view.
			vm.isContentLoaded = false;
			vm.isContentValid = true;

			$timeout(() => {
				vm.onStart({view});
			});
		});

		vm.wizard.onRevisit((view) => {
			vm.onRevisit({view});
			$timeout(() => {});
		});

		vm.wizard.onEnd((view) => {
			vm.setContentLoaded(false);
			vm.onEnd({view});
			$timeout(() => {});
		});

		vm.wizard.result.then(() => {
			$timeout(() => {
				vm.onComplete();
			});
		});
	};

	vm.hasNext = () => {
		return vm.wizard.hasNext();
	};

	vm.next = () => {
		vm.wizard.next();
	};

	vm.hasPrev = () => {
		return vm.wizard.hasPrev();
	};

	vm.prev = () => {
		vm.wizard.prev();
	};

	vm.onSubmit = () => {
		const hasNext = vm.wizard.hasNext();
		const onBeforeLeave = vm.wizard.currentView.onBeforeLeave ?? angular.noop;
		const onEnd = vm.wizard.currentView.onEnd ?? angular.noop;

		return $q.when(onBeforeLeave())
			.then(() => $q.when(onEnd()))
			.then(() => {
				if (hasNext) {
					vm.next();
				} else {
					vm.finish();
				}
			}).catch((e) => {
				$log.error('WizardController.onSubmit failed!', e);
			});
	};

	vm.finish = () => {
		vm.wizard.finish();
	};

	vm.setContentLoaded = (value) => {
		vm.isContentLoaded = !!value;
	};

	vm.setContentValid = (value) => {
		vm.isContentValid = !!value;
		vm.safeDigest();
	};

	vm.nextButtonEnabled = () => {
		return vm.isContentLoaded && vm.isContentValid;
	};

	vm.showPrevButton = () => {
		const prevView = vm.wizard.getPrevView();
		return prevView && !prevView.hideNextPrevButton;
	};

	vm.doneButtonEnabled = () => {
		return vm.isContentLoaded && vm.isContentValid;
	};

	vm.hideFooter = () => {
		return !!vm.wizard.currentView.hideFooter;
	};

	/**
	 * Whether or not wizard header and footer should be displayed
	 */
	vm.shouldHideHeaderAndFooter = () => {
		return !!$location.search().modalOpen;
	};

	vm.safeDigest = () => {
		// trigger digest so button can reflect changes after ngx changes
		// eslint-disable-next-line angular/no-private-call
		const phase = $scope.$root.$$phase || $scope.$$phase;
		if (!phase) {
			$scope.$digest();
		}
	};
}
