import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';

export enum LICENSE_PRODUCT_SEAT_TYPES {
	USER = 'user',
	COURSE = 'course'
}

export interface Product {
	product_id: number;
	display_name: string;
	description: string;
	price: string;
	upload_limit: number;
	active: boolean;
	is_custom: boolean;
	is_active: boolean;
	product_type: string;
	is_plan: boolean;
	plan_duration_days?: number;
	tax_code?: string;
	sort_order?: number;
	plan_duration_months: number;
	base_product_id: number;
	base_product_qty: number;
	product_slug: string;
	customProducts?: CustomProduct[];
	licenseProduct?: LicenseProduct;
	actionTranslation?: string;
	hasAction?: boolean;
}

export interface CustomProduct {
	group_id: number;
	product_id: number;
	expires_at: Date;
}

export interface LicenseProduct {
	id: number;
	product_id: number;
	duration_months: number;
	seat_type: string;
	tier_name: ProductTier;
	upload_limit_mb: number;
	video_quality: string;
	data_api_enabled: boolean;
	transcriptions_enabled: boolean;
	transcript_analytics_enabled: boolean;
	ai_prompts_enabled: boolean;
	seat_removal_policy: string;
	seat_overage_policy: string;
	expiration_policy: string;
	num_seats?: number;
	is_free_trial: boolean;
	created_at?: Date | string;
	updated_at?: Date | string;
	product?: Product;
}
