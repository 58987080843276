import { PaymentMethodPayload } from 'braintree-web-drop-in';

import { LICENSE_PRODUCT_SEAT_TYPES } from '../../services/group/product';
import type { Product } from '../../services/group/product';
import { Address } from 'go-modules/payment-panel/address-details-form/address-details-form.controller';
import { TaxEstimate } from '../../models/avalara/avalara.service';
import { LicenseDetailsForm } from '../license-details-form/license-details-form.controller';
import { NgxPurchaseTypeService } from 'ngx/go-modules/src/services/purchase-type/purchase-type.service';

export class ReviewPaymentController {
	public paymentMethod: PaymentMethodPayload;
	public product: Product;
	public options: any;
	public group: any;
	public address: Address;
	public taxEstimate: TaxEstimate;
	public licenseDetails: LicenseDetailsForm;

	/* @ngInject */
	constructor (
		public $scope: ng.IScope,
		public ngxPurchaseTypeService: NgxPurchaseTypeService
	) {}

	public get licenseTypeTranslationKey (): string {
		return this.licenseDetails.selectedProduct.licenseProduct.seat_type === LICENSE_PRODUCT_SEAT_TYPES.USER ?
			'payment-panel-license-details_license-type-multi-course' :
			'payment-panel-license-details_license-type-single-course';
	}

	public shouldShowProrate (): boolean {
		return this.licenseDetails && this.licenseDetails.prorateAdjustment != null;
	}
}
