import { IComponentOptions } from 'angular';
import { LicensePaymentWizardViewController } from './license-payment-wizard-view.controller';

export const LicensePaymentWizardViewComponent = {
	bindings: {
		onError: '&',
		onSuccess: '&'
	},
	controller: LicensePaymentWizardViewController,
	require: {
		wizardController: '^wizard'
	},
	template: require('./license-payment-wizard-view.component.html')
} as IComponentOptions;
