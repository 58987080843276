import { Component, Input } from '@angular/core';

@Component({
	selector: 'wizard-view',
	template: require('./wizard-view.component.html'),
	styles: [require('./wizard-view.component.scss')]
})
export class WizardViewComponent {
	@Input() public title?: string; // Translated Text
	@Input() public titleKey?: string; // Translation Key

	public get hasTitle (): boolean {
		return !!this.title?.length;
	}

	public get hasTitleKey (): boolean {
		return !!this.titleKey?.length;
	}
}
