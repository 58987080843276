/* @ngInject */
export class ChoosePlanWizardViewController  {
	public wizardController: any;

	constructor (
		public $log,
		private appData
	) {}

	public selfPaySelect () {
		const choosePlanIndex = this.wizardController.views.findIndex((view) => {
			return view.template === '<choose-plan-wizard-view></choose-plan-wizard-view>';
		});
		this.wizardController.views.splice(choosePlanIndex + 1, 0, {
			template: '<license-payment-wizard-view></license-payment-wizard-view>'
		});
		this.wizardController.next();
	}

	public studentPaySelect () {
		const choosePlanIndex = this.wizardController.views.findIndex((view) => {
			return view.template === '<choose-plan-wizard-view></choose-plan-wizard-view>';
		});
		this.wizardController.views.splice(choosePlanIndex + 1, 0, {
			onEnd: () => {
				this.completeCourseSetup(true);
			},
			onRevisit: () => {
				this.completeCourseSetup(false);
			},
			template: '<course-setup-wizard-view></course-setup-wizard-view>'
		});
		this.wizardController.next();
	}

	public completeCourseSetup (value) {
		return this.appData.userGroup.setupComplete(value)
			.$promise
			.catch((response) => {
				this.$log.error('Failed to setup course!', response);
			});
	}
}
