import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Need to Create a static class so we can mock this in angularjs unit test
// this is a temporary until we able to mock httpClient in the angularjs
export class GoTranslateLoader {
	public static factory (http: HttpClient): TranslateLoader {
		return GoTranslateLoader.makeLoader(http);
	}

	public static makeLoader (http: HttpClient): TranslateLoader {
		return new TranslateHttpLoader(http, 'https://staticassets.goreact.com/prod-client/lti/translations/translation/', '.json?v=1724774063561');
	}
}
