import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';

export const LMS_INSTRUCTION_URLS = {
	BLACKBOARD: helpUrlsLookup.blackboardSetup,
	BRIGHTSPACE: helpUrlsLookup.brightspaceSetup,
	CANVAS: helpUrlsLookup.canvasSetup,
	DEFAULT: helpUrlsLookup.instructorHelp,
	MOODLE: helpUrlsLookup.moodleSetup,
	SCHOOLOGY: helpUrlsLookup.schoologySetup
};

export const getUrlByLmsName = (lmsName: string, mappings: any): string  => {

	const name = (mappings?.[lmsName] || '').toUpperCase();

	if (LMS_INSTRUCTION_URLS[name]) {
		return LMS_INSTRUCTION_URLS[name];
	}

	return LMS_INSTRUCTION_URLS.DEFAULT;
};
