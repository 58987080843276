import * as angular from 'angular';
import { WizardFactory } from './wizard.factory';
import { WizardComponent } from './wizard.component';
import { wizardContentDirective } from './wizard-content.directive';
import { offsetScrollbarDirective } from './offset-scrollbar.directive';
import { wizardViewModule } from './wizard-view';
import { event } from 'go-modules/event-channel';

export const wizardModule = angular
	.module('app.wizard', [
		wizardViewModule,
		event
	])
	.factory('Wizard', WizardFactory)
	.component('wizard', WizardComponent)
	.directive('wizardContent', wizardContentDirective)
	.directive('offsetScrollbar', offsetScrollbarDirective)
	.name;
