import * as angular from 'angular';
import { QuickStartGuideComponent } from './quick-start-guide.component';
import { appDataModule } from '../app-data';

export const quickStartGuideModule = angular
	.module('app.quickStartGuide', [
		appDataModule.name
	])
	.component('quickStartGuide', QuickStartGuideComponent)
	.name;
