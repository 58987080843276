import { Component, Input, OnInit } from '@angular/core';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';
import type { LtiLmsNameMappings } from 'lti/app-error/app-error.controller';

@Component({
	selector: 'missing-parameters-error-renderer',
	template: require('./missing-parameters-error.component.html'),
	styles: [require('./missing-parameters-error.component.scss')]
})
export class MissingParametersErrorComponent implements OnInit {
	@Input() public errors: any;
	@Input() public mappings: LtiLmsNameMappings;

	public showErrors = false;
	public setupInstructionsLink: string;
	public contactSupport: string;

	public ngOnInit (): void {
		this.contactSupport = helpUrlsLookup.contactSupport;
		// This link is the required params page
		this.setupInstructionsLink = helpUrlsLookup.lmsIntegrationDetails;
	}

	public toggleErrors (): void {
		this.showErrors = !this.showErrors;
	}
}
