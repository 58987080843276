import { IComponentOptions } from 'angular';
import { DefaultErrorRendererController } from './error-renderer.controller';

export const DefaultErrorRendererComponent = {
	bindings: {
		errors: '<',
		lmsName: '@',
		mappings: '<'
	},
	controller: DefaultErrorRendererController,
	template: require('./error-renderer.component.html')
} as IComponentOptions;
