import { Component, Input, OnInit } from '@angular/core';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';

@Component({
	selector: 'activity-not-found-error-renderer',
	template: require('./activity-not-found-error.component.html'),
	styles: [require('./activity-not-found-error.component.scss')]
})
export class ActivityNotFoundErrorComponent implements OnInit {
	@Input() public errors: any;
	public contactSupport: string;

	public ngOnInit (): void {
		this.contactSupport = helpUrlsLookup.contactSupport;
	}
}
