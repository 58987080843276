import { BraintreeService as BTService } from 'go-modules/braintree/braintree.service';
import { Dropin } from 'braintree-web-drop-in';

export class PayWithCardController {

	//bindings
	public options;

	public loadingPayFormPromise: ng.IPromise<Dropin>;
	public loadingPayForm = false;
	public payFormError = false;
	public dropinInstance: Dropin;
	public group: any;
	public formLoaded: ({dropinInstance: Dropin}) => void;

	/* @ngInject */
	constructor (
		private $log: ng.ILogService,
		public BraintreeService: BTService,
		public $translate: ng.translate.ITranslateService
	) {}

	public $onInit () {
		this.initBraintreeForm();
	}

	public $onDestroy () {
		// Cleanly tear down braintree dropin instance
		this.loadingPayFormPromise.then((dropinInstance) => {
			dropinInstance?.teardown();
		});
	}

	private paymentFormLoaded () {
		this.formLoaded({dropinInstance: this.dropinInstance});
	}

	private initBraintreeForm () {
		this.loadingPayForm = true;

		this.loadingPayFormPromise = this.BraintreeService.generateClientToken()
			.then((clientToken) => {
				const braintreeCardOptions = {
					vault: {
						allowVaultCardOverride: true,
						vaultCard: true
					},
					overrides: {
						styles: {
							input: {
								'font-size': '14px',
								'color': '#555',
								'font-family': 'proxima-nova, "Open Sans", sans-serif'
							}
						}
					}
				};

				const lang = this.$translate.proposedLanguage() || this.$translate.use();

				return this.BraintreeService.createBraintreeForm({
					authorization: clientToken.token,
					container: '#braintree-v3-pay-form',
					locale: lang,
					vaultManager: true,
					card: braintreeCardOptions
				});
			}).then((dropinInstance) => {
				this.dropinInstance = dropinInstance;
				this.paymentFormLoaded();
				return dropinInstance;
			}).catch((err) => {
				this.$log.error(err);
				this.payFormError = true;
				return null;
			}).finally(() => {
				this.loadingPayForm = false;
			});
	}
}
