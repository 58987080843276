import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'ngx-user-session-expiration',
	template: require('./user-session-expiration.component.html'),
	styles: [require('./user-session-expiration.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSessionExpirationComponent {

	constructor () {}

}
