import template from './product-details-form.component.html';
import { ProductDetailsFormController as controller } from './product-details-form.controller';

export const ProductDetailsFormComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		group: '<',
		products: '<',
		optionProduct: '<',
		onSelectedProductChange: '&'
	},
	require: {
		formController: '^form'
	}
};
