// Import vendor dependencies
import * as angular from 'angular';
import * as angularAria from 'angular-aria';
import * as angularAnimate from 'angular-animate';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as uiDropdown from 'angular-ui-bootstrap/src/dropdown';
import * as angulartics from 'angulartics';
import * as angularticsGoogleAnalytics from 'angulartics-google-analytics';
import * as angularTranslate from 'angular-translate';
import 'angular-translate-loader-static-files';
import 'bootstrap-css-only';
import 'angular-material/modules/js/core/core.css';
import 'go-styles/src/goreact-style.less';
import 'go-ui/material-theme.scss';
import 'go-ui/go-ui.scss';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import { appRunModule } from 'go-modules/app-run';
import { sessionManager } from 'go-modules/session-manager';
import { goPubnubModule } from 'go-modules/go-pubnub';
import { analyticsModule } from 'go-modules/services/analytics';
import { fineUploaderModule } from 'go-modules/fine-uploader';
import { mediaPlayerModule } from 'go-modules/media-player';
import { uibModalDecoratorModule } from 'go-modules/$uib-modal-decorator';
import { common } from 'go-modules/models/common';
import { updateManagerModule } from 'go-modules/update-manager';

// App configuration
import { ENVIRONMENT_CONSTANTS } from './environment';
import {
	environmentLoggingConfig,
	sessionManagerConfig,
	compileProviderConfig,
	unhandledRejectionConfig
} from './config';
import {
	animationsRunBlock,
	initializeWizardRunBlock,
	addUnsavedTranscriptionUsage,
	initFullstory,
	initGoogleAnalytics,
	initPubnub,
	initBannerNotifications,
	initFineUploader,
	removeCurrentFilter
} from './run';


// App modules
import { appRootModule } from './app-root';
import { routeViewsModule } from './route-views';
import { wizardModule } from './wizard';
import { quickStartGuideModule } from './quick-start-guide';
import { accountEditorModule } from 'ngx/lti/src/components/account-editor';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { userModel } from 'go-modules/models/user';
import { bannerNotificationsModule } from 'go-modules/services/banner-notifications';
import { watsonUsageTrackingModule } from 'go-modules/services/watson-usage-tracking';
import { SelectedModule } from 'go-modules/services/selected';
import { goPageModule } from 'go-modules/go-page';
import { goBootstrapModule } from './downgraded-app.module';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { TranslateService } from '@ngx-translate/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { eventServiceModule } from 'ngx/go-modules/src/services/event';
import { ngxGoModalServiceModule } from 'ngx/go-modules/src/services/go-modal';
import { groupSettingsPanelModule } from 'go-modules/group-settings-panel';
import { GoLocationModule } from 'go-modules/services/go-location';
import { ngxLicenseSeatsManagementModule } from 'ngx/go-modules/src/components/license-seats-management';
import { ngxUserSessionExpirationModule } from 'ngx/lti/src/components/user-session-expiration';
import uiRouter from '@uirouter/angularjs';
import { routingConfig } from './route-views/routing.config';
import { ngxArchiveViewModule } from 'ngx/go-modules/src/components/archive/archive-view';
import { ngxLibraryCollectionViewerModule } from 'ngx/go-modules/src/components/library/library-collections-viewer';
// Circular dependency
// tslint:disable-next-line:import-spacing
import /*{ activityEditorPanelModule } from*/ 'go-modules/activity-editor-panel';

export const appModule: string = angular
	.module('app', [
		goBootstrapModule,
		goLocalizeHelperModule,
		appRootModule,
		routeViewsModule,
		wizardModule,
		quickStartGuideModule,
		accountEditorModule,
		angularAria,
		angularAnimate,
		uiDropdown,
		uiTooltip,
		angularTranslate,
		angulartics,
		angularticsGoogleAnalytics,
		sessionManager,
		goPubnubModule,
		analyticsModule,
		fineUploaderModule,
		mediaPlayerModule,
		ngxUserSessionExpirationModule,
		appRunModule,
		uibModalDecoratorModule,
		common,
		updateManagerModule,
		fullstoryModule,
		userModel,
		bannerNotificationsModule,
		watsonUsageTrackingModule,
		goPageModule,
		SelectedModule,
		GoLocationModule,
		eventServiceModule,
		ngxGoModalServiceModule,
		ngxLicenseSeatsManagementModule,
		ngxArchiveViewModule,
		// imported for expiring license component
		// we dont ever actually want to show groupsettingspanel in lti
		groupSettingsPanelModule,
		uiRouter,
		ngxLibraryCollectionViewerModule,
		'activity-editor-panel'
	])
	.constant('ENVIRONMENT_CONSTANTS', ENVIRONMENT_CONSTANTS)
	.config(unhandledRejectionConfig)
	.config(environmentLoggingConfig)
	.config(sessionManagerConfig)
	.config(compileProviderConfig)
	.config(routingConfig)
	.run(animationsRunBlock)
	.run(initializeWizardRunBlock)
	.run(addUnsavedTranscriptionUsage)
	.run(initFullstory)
	.run(initPubnub)
	.run(initFineUploader)
	.run(initGoogleAnalytics)
	.run(initBannerNotifications)
	.run(removeCurrentFilter)
	.factory('ngxTranslationService', downgradeInjectable(TranslateService))
	.name;
