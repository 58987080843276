import { UserService } from 'go-modules/models/user/user.service';

/* @ngInject */
export function LinkUserModalController ($scope, UserAccountModel, userService: UserService) {

	const vm = this;

	vm.$onInit = () => {
		$scope.submitButtonText = $scope.submitButtonText || 'modal-link-user_controller-button-text';
		$scope.description = $scope.description || 'modal-link-user_controller-description';

		$scope.user = {
			username: '',
			password: ''
		};

		$scope.error = '';
		$scope.processing = false;
	};

	$scope.clearError = function () {
		$scope.error = '';
	};

	$scope.submit = function () {
		$scope.processing = true;

		const result = UserAccountModel.save({
			username: $scope.user.username,
			password: $scope.user.password
		});

		result.$promise.then(function (response) {
			userService.currentUser.is_account_linking_enabled = false;
			$scope.$close(response);
		});

		result.$promise.catch(function (response) {
			const status = response.status ?? 400;
			$scope.user.password = '';

			switch (status) {
				case 400:
				case 404:
					$scope.error = 'modal-link-user_controller-error';
					break;
				default:
					$scope.error = response.data.message;
					break;
			}
		});

		result.$promise.finally(function () {
			$scope.processing = false;
		});

		return result;
	};
}
