import { PaymentPanel } from 'go-modules/payment-panel/payment-panel.service';
import { LICENSE_TRANSACTION_TYPE, PAYMENT_TYPES, PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { NgxSelfPayService } from 'ngx/go-modules/src/services/self-pay';
import { GroupService } from 'go-modules/services/group/group.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';

/* @ngInject */
export class LicensePaymentWizardViewController  {
	public wizardController: any;
	public modalInstance: any;
	public isDestroyed: boolean = false;

	constructor (
		private $ocLazyLoad: oc.ILazyLoad,
		private appData,
		private $injector,
		private $element,
		private $scope,
		private ngxSelfPayService: NgxSelfPayService,
		private ngxDowngradeModalService: DowngradeModalService,
		private featureFlag: FeatureFlag,
		private GroupService: GroupService,
		private selectedService: SelectedService,
		private ngxGoToastService: NgxGoToastService
	) {}

	public $onInit () {
		// Dismiss the instance to remove this from $uibModalStack
		this.$scope.$on('$destroy', (): void => {
			this.modalInstance?.dismiss();
			this.isDestroyed = true;
		});

		let selectedProduct = null;

		let productSelectionPromise = Promise.resolve();

		if (this.featureFlag.isAvailable('LICENSE_UPGRADE_PURCHASE')) {
			productSelectionPromise = new Promise((resolve, reject) => {
				this.ngxSelfPayService.getProducts(this.appData.org_id).subscribe({
					next: (products) => {
						this.ngxDowngradeModalService.openCompareProductsDialog({
							transactionType: LICENSE_TRANSACTION_TYPE.INITIAL,
							license: null,
							products
						}).afterClosed().subscribe((returnedProduct) => {
							selectedProduct = returnedProduct;
							resolve();
						});
					},
					error: () => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'common_unknown-error_message'
						});
						reject();
					}
				});
			});
		}

		productSelectionPromise.then(() => {
			const importPromise = import(/* webpackChunkName: "PaymentPanel" */ 'go-modules/payment-panel')
				.then((module: any) => {
					return this.$ocLazyLoad.load(module.paymentPanelModule);
				}).catch((error) => {
					throw new Error(`Failed to lazy load PaymentPanel module: ${error.message}`);
				});

			importPromise.then(() => {
				const paymentPanel: PaymentPanel = this.$injector.get('PaymentPanel');
				this.modalInstance = paymentPanel.open({
					user: this.appData.currentUser,
					group: this.appData.myAccount,
					type: PAYMENT_TYPES.CARD,
					purchaseType: PURCHASE_TYPES.LICENSE,
					licenseTransactionType: LICENSE_TRANSACTION_TYPE.INITIAL,
					license: null,
					ltiWizard: true,
					selectedProduct
				},
				false,
				' center-side-panel',
				this.$element[0].querySelector('.panel-body')
				);

				this.modalInstance.result
					.then(() => {
						const selectedGroup = this.selectedService.getGroup();
						this.GroupService.getLicense(selectedGroup.group_id).then((license) => {
							this.selectedService.setLicense(license);

							selectedGroup.upload_limit = license.salesforce_license.upload_limit_mb;
							this.selectedService.setGroup(selectedGroup);
						});
					})
					.finally(() => {
						if(!this.isDestroyed) {
							this.wizardController.next();
						}
					});
			});
		}).catch(() => {});
	}
}
