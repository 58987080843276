import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';

/* @ngInject */
export function CourseSetupWizardViewController (appData, featureFlag: FeatureFlag) {
	const vm = this;

	vm.$onInit = () => {
		vm.course = appData.userGroup;
		vm.options = {
			isLti: true,
			hideName: true
		};
		vm.featureFlag = featureFlag;
		vm.hasLicense = true;
		vm.licenseTrigger$ = null;
	};
}
