import { GoLocationService } from 'go-modules/services/go-location/go-location.service';

/* @ngInject */
export class LicenseSeatsManagementRouteViewController implements ng.IController {
	public license;
	public viewByUser;

	/* @ngInject */
	constructor (
		public LicenseModel,
		public $stateParams,
		public goLocationService: GoLocationService
	) {}

	public $onInit (): void {
		this.viewByUser = this.goLocationService.search().viewByUser;
		this.LicenseModel.getLicense(this.$stateParams.licenseId)
			.then((license) => this.license = license);
	}
}
