import { UserService } from 'go-modules/models/user/user.service';
import type { GroupsIndexService } from 'go-modules/groups-index/groups-index.service';
import type { StateParams } from '@uirouter/angularjs';
import { AppEnvService } from 'go-modules/go-app-env/app-env.service';

/* @ngInject */
export class LicenseManagementRouteViewController implements ng.IController {
	public myAccount;
	public org;

	/* @ngInject */
	constructor (
		public userService: UserService,
		public $stateParams: StateParams,
		private groupsIndex: GroupsIndexService,
		private appData,
		public appEnv: AppEnvService
	) {}

	public $onInit (): void {
		this.myAccount = this.appData.myAccount;

		if (this.$stateParams.orgId != null) {
			this.groupsIndex.resolve(this.$stateParams.orgId).then((org) => {
				this.org = org;
			});
		}
	}
}
