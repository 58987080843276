import { FormValidationService } from 'go-modules/form-validation/form-validation.service';

export interface Address {
	line1: string;
	line2?: string;
	city: string;
	region: string;
	postal_code: string;
	country: string;
}

export interface AddressInputForm extends ng.IFormController {
	line1: ng.INgModelController;
	line2?: ng.INgModelController;
	city: ng.INgModelController;
	postal_code: ng.INgModelController;
	region: ng.INgModelController;
	country: ng.INgModelController;
}

export interface Bindings {
	formController: AddressInputForm;
}

export class AddressDetailsFormController implements Bindings {

	public formController: AddressInputForm;
	public formValidationService: FormValidationService;
	public onAddressChange: (value) => void;
	public address: Address = {
		line1: '',
		line2: '',
		city: '',
		region: '',
		postal_code: '',
		country: ''
	};

	/* @ngInject */
	constructor () {}

	public $onInit () {
		this.formValidationService = new FormValidationService(this.formController);
	}

	public addressChange () {
		this.onAddressChange({address: this.address});
	}
}
