import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AccountEditorComponent } from './account-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { TrackByPropPipeModule } from 'ngx/go-modules/src/pipes/track-by-prop/track-by-prop-pipe.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		FocusOnFirstInvalidFieldModule,
		GoMaterialModule,
		TrackByPropPipeModule
	],
	exports: [
		AccountEditorComponent
	],
	declarations: [
		AccountEditorComponent
	],
	entryComponents: [
		AccountEditorComponent
	]
})
export class AccountEditorModule {}
