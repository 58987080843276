import { States } from 'go-modules/enums/states.enum';

/* @ngInject */
export function WizardRouteViewController (
	$log, $window,
	appData,
	$state,
	$stateParams
) {
	const vm = this;

	/**
	 * Handles controller init
	 */
	vm.$onInit = () => {
		// List of wizard views
		vm.views = [];
		// Add setup account wizard view when prompted to do so
		if ($stateParams.setupAccount) {
			vm.views.push({
				template: '<account-setup-wizard-view></account-setup-wizard-view>',
				onEnd: () => {
					appData.prompts.setupAccount = false;
					$window.sessionStorage.setItem('appData', JSON.stringify(appData));
				}
			});
		}

		// Add course payment wizard view when prompted to do so
		if ($stateParams.pay) {
			vm.views.push({
				hideFooter: true,
				hideNextPrevButton: true,
				template: '<course-payment-wizard-view></course-payment-wizard-view>',
				onEnd: () => {
					appData.prompts.pay = false;
					appData.prompts.setupLicense = false;
					$window.sessionStorage.setItem('appData', JSON.stringify(appData));
				}
			});
		}

		// Add course setup and quick start guide wizard view when prompted to setup of the course
		if ($stateParams.setupCourse) {
			vm.views.push({
				template: '<quick-start-guide-wizard-view></quick-start-guide-wizard-view>',
				onEnd: () => {
					appData.prompts.setupCourse = false;
					$window.sessionStorage.setItem('appData', JSON.stringify(appData));
				}
			});

			// Add choose plan step if it is their first time
			if ($stateParams.setupLicense) {
				vm.views.push({
					template: '<choose-plan-wizard-view></choose-plan-wizard-view>',
					onRevisit: () => {
						const courseSetupIndex = vm.views.findIndex((view) => {
							return view.template === '<course-setup-wizard-view></course-setup-wizard-view>';
						});
						if (courseSetupIndex >= 0) {
							vm.views.splice(courseSetupIndex, 1);
						}
						const licensePaymentIndex = vm.views.findIndex((view) => {
							return view.template === '<license-payment-wizard-view></license-payment-wizard-view>';
						});
						if (licensePaymentIndex >= 0) {
							vm.views.splice(licensePaymentIndex, 1);
						}
					},
					onEnd: () => {
						appData.prompts.setupLicense = false;
						$window.sessionStorage.setItem('appData', JSON.stringify(appData));
						vm.completeCourseSetup(true);
					}
				});
			} else {
				vm.views.push({
					onEnd: () => {
						appData.prompts.setupLicense = false;
						$window.sessionStorage.setItem('appData', JSON.stringify(appData));
						vm.completeCourseSetup(true);
					},
					onRevisit: () => {
						vm.completeCourseSetup(false);
					},
					template: '<course-setup-wizard-view></course-setup-wizard-view>'
				});
			}
		}

		// Add activity editor wizard view when prompted to do so
		if ($stateParams.editActivity) {
			vm.views.push({
				onBeforeLeave: () => {
					return appData.activity.save();
				},
				onEnd: () => {
					appData.prompts.editActivity = false;
					appData.prompts.setupActivity = false;
					appData.prompts.setupLicense = false;
					$window.sessionStorage.setItem('appData', JSON.stringify(appData));
					return vm.completeActivitySetup(true).finally(vm.followRedirectAfterSetup);
				},
				onRevisit: () => {
					vm.completeActivitySetup(false);
				},
				template: '<activity-editor-wizard-view></activity-editor-wizard-view>'
			});
		}

		// When there are no views, complete wizard immediately
		if (!vm.views.length) {
			vm.onComplete();
		}
	};

	vm.followRedirectAfterSetup = () => {
		const followUrl = appData.lti.followLtiReturnUrl;
		const returnUrl = appData.lti.launchPresentationReturnUrl;
		if (followUrl && returnUrl) {
			$window.location = returnUrl;
		}
	};

	/**
	 * Handles wizard complete event
	 */
	vm.onComplete = () => {
		$state.go(States.DASHBOARD_ACTIVITY_VIEW, {activity_id: appData.activity.activity_id});
	};

	/**
	 * Complete course setup service call
	 *
	 * @param value
	 * @returns {*}
	 */
	vm.completeCourseSetup = (value) => {
		return appData.userGroup.setupComplete(value)
			.$promise
			.catch((response) => {
				$log.error('Failed to setup course!', response);
			});
	};

	/**
	 * Complete activity setup service call
	 *
	 * @param value
	 * @returns {*}
	 */
	vm.completeActivitySetup = (value) => {
		return appData.activity.setupComplete(value)
			.$promise
			.catch((response) => {
				$log.error('Failed to setup activity!', response);
			});
	};
}
