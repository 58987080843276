import { ThemeEngine } from '../theme';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { GoBannerService } from 'go-modules/go-banner/go-banner.service';
import { AnnouncementType } from 'go-modules/models/announcement/announcement.type';
import dayjs from 'dayjs';

/* @ngInject */
export function AppRootController (
	$location,
	$scope,
	appData,
	sessionManager,
	User,
	fullstoryService: FullstoryService,
	selectedService: SelectedService,
	eventService: EventService,
	$window: ng.IWindowService,
	featureFlag: FeatureFlag,
	goBannerService: GoBannerService,
	$translate: angular.translate.ITranslateService,
	Group,
	ActivityModel,
	$q: ng.IQService
) {
	const vm = this;
	vm.isIframe = false;

	function setTitle (activity) {
		ThemeEngine.setPageTitle(ThemeEngine.getTheme(appData), activity);
	}

	/**
	 * Handles controller init
	 */
	vm.$onInit = () => {
		const customTheme = $window.goSettings.themes[appData.theme];
		const faviconPath = customTheme ? customTheme.icoPath : 'favicon-gray-square-2020.svg';
		const shortcutIconHref = 'https://staticassets.goreact.com/' + faviconPath;
		const shortcutIconLinkTag = $window.document.createElement('link');
		shortcutIconLinkTag.setAttribute('rel', 'shortcut icon');
		shortcutIconLinkTag.setAttribute('href', shortcutIconHref);
		$window.document.getElementsByTagName('head')[0].appendChild(shortcutIconLinkTag);

		vm.user = appData.currentUser;
		vm.group = appData.userGroup;
		vm.activity = appData.activity;
		selectedService.setMyOrgs(appData.myOrgs);
		const org = appData.myOrgs.find((item: any) => item.group_id === appData.org_id);
		selectedService.setUser(appData.filterByStudent);
		selectedService.setOrg(Group.model(org));
		selectedService.setAccount(appData.myAccount);
		selectedService.setGroup(vm.group, vm.activity);
		selectedService.setActivity(vm.activity);

		if ($window.self !== $window.top) {
			vm.isIframe = true;
		}

		vm.getAllActivityStats().then(() => {
			selectedService.setGroups([vm.group]);
			vm.sessionManager = sessionManager;

			setTitle(vm.activity);

			fullstoryService.createEvent(FULLSTORY_EVENTS.USER_CONTEXT_IDENTIFIED, {
				userRole_str: vm.group.role,
				groupId_int: parseInt(vm.group.group_id, 10),
				orgId_int: parseInt(vm.group.org_id, 10)
			});

			if (vm.group.hasInstructorRole(true)) {
				fullstoryService.createEvent(FULLSTORY_EVENTS.USE_TYPE_IDENTIFIED, {
					useTypeId_int: parseInt(vm.user.use_type_id, 10)
				});

				if (featureFlag.isAvailable('WARN_LTI_USERS_UPDATE_CONFIG') && appData.warning_translation) {
					const daysRemaining = appData.warning_translation.daysRemaining;

					if (daysRemaining < 0) {
						const knownIssues = Object.keys(appData.warning_translation.issues).filter((key) => {
							if (appData.warning_translation.issues[key]) {
								return key;
							}
						}).join(', ');

						$translate('lti-warning_deprecated', {
							issues: knownIssues
						}).then((message) => {
							fullstoryService.createEvent(FULLSTORY_EVENTS.LTI_WARNING, { message });

							goBannerService.addBanner({
								content: message,
								name: 'lti-warning',
								isDismissable: false,
								maxViews: 10000,
								type: AnnouncementType.WARNING
							});
						});
					} else {
						$translate(appData.warning_translation.translate_key, {
							date: dayjs(appData.warning_translation.date).format('MMM D, YYYY'),
							daysRemaining
						}).then((message) => {
							let announcementType = AnnouncementType.INFO;
							if (daysRemaining <= 14) {
								announcementType = AnnouncementType.WARNING;
							}

							fullstoryService.createEvent(FULLSTORY_EVENTS.LTI_WARNING, { message });

							goBannerService.addBanner({
								content: message,
								name: 'lti-warning',
								isDismissable: true,
								maxViews: 10000,
								type: announcementType
							});
						});
					}
				}
			}

			// determine students plan
			User.getBillingBalances({
				userId: vm.user.user_id
			}).$promise.then((res) => {
				vm.user.plan = res.plan;
				vm.user.totalStudentCredits = res.credits.reduce((total, credit) => {
					return total + parseInt(credit.units_available, 10);
				}, 0);
			});

			vm.eventNames = [
				EVENT_NAMES.GO_MODAL_OPENED,
				EVENT_NAMES.GO_MODAL_ALL_CLOSED
			];
			vm.eventSubscription = eventService.events
				.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
				.subscribe((ev: GoEvent) => {
					switch (ev.name) {
						case EVENT_NAMES.GO_MODAL_OPENED:
							$location.search('modalOpen', 'yes');
							break;
						case EVENT_NAMES.GO_MODAL_ALL_CLOSED:
							$location.search('modalOpen', null);
							break;
					}
				});

			// Wait for the user session to expire and
			// then force angular to run change detection.
			vm.sessionManager.on('timeout', () => {
				$scope.$apply();
			});
		});
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	// TODO DEV-15860
	vm.getAllActivityStats = () => {
		if (vm.activity) {
			return ActivityModel.getAllActivityStats({ group_id: vm.activity.group_id }).$promise;
		}

		return $q.resolve();
	};
}
