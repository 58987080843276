import template from './enter-information.component.html';
import { EnterInformationController as controller } from './enter-information.controller';

export const EnterInformationComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		group: '<',
		products: '<',
		product: '<',
		options: '<',
		mode: '<',
		licenseDetailsForm: '=',
		isTaxExempt: '=',
		onProductChange: '&',
		payFormHasLoaded: '&',
		onAddressChange: '&'
	},
	require: {
		formController: '^form'
	}
};
