import { getUrlByLmsName } from '../lms-instruction-urls';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';

interface Bindings {
	errors: string[];
	lmsName: string;
	mappings: any;
}

export class DefaultErrorRendererController implements Bindings {
	public errors: any;
	public lmsName: string;
	public setupInstructionsLink: string;
	public mappings: any;
	public contactSupport: string;

	public $onInit () {
		this.setupInstructionsLink = getUrlByLmsName(this.lmsName, this.mappings);
		this.contactSupport = helpUrlsLookup.contactSupport;
	}
}
