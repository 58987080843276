import template from './review-payment.component.html';
import { ReviewPaymentController as controller } from './review-payment.controller';

export const ReviewPaymentComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		group: '<',
		options: '<',
		paymentMethod: '<',
		product: '<',
		address: '<',
		licenseDetails: '<',
		taxEstimate: '<'
	}
};
