import template from './license-details-form.component.html';
import { LicenseDetailsFormController as controller } from './license-details-form.controller';

export const LicenseDetailsFormComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		products: '<',
		options: '<',
		licenseDetailsForm: '=',
		formLoaded: '&'
	},
	require: {
		formController: '^form'
	}
};
