import * as angular from 'angular';

import {
	QuickStartGuideRouteViewComponent
} from './quick-start-guide-route-view/quick-start-guide-route-view.component';
import { WizardRouteViewComponent } from './wizard-route-view/wizard-route-view.component';
import { appRootModule } from '../app-root';
import { courseEditorModule } from 'go-modules/course-editor';
import { feedbackSessionModule } from 'go-modules/feedback-session';
import { ngxLicenseManagementModule } from 'ngx/go-modules/src/components/license-management';
import { groupDepModel } from 'go-modules/models/group-dep';
import { licenseManagementRouteViewComponent } from './license-management-route-view/license-management-route-view.component';
import { groupsIndexModule } from 'go-modules/groups-index';
import { groupServiceModule } from 'go-modules/services/group';
import { licenseSeatsManagementRouteViewComponent } from './license-seats-management/license-seats-management-route-view.component';
import { ngxLicenseSeatsManagementModule } from 'ngx/go-modules/src/components/license-seats-management';
import { GoLocationModule } from 'go-modules/services/go-location';
import { ngxUserSettingsModule } from 'ngx/go-modules/src/components/user-settings';
import { ngxFolderViewModule } from 'ngx/go-modules/src/components/folder-view';
import { ngxFoldersViewModule } from 'ngx/dashboard/src/components/folders-view';
import { ngxActivityViewModule } from 'ngx/go-modules/src/components/activity-view';
import { activityReportPanelModule } from 'go-modules/activity-report-panel';
import { groupCreatorModule } from 'go-modules/group-creator';
import { ngxSessionViewModule } from 'ngx/go-modules/src/components/feedback-session/session-view';
import { ngxTermsServiceModule } from 'ngx/go-modules/src/services/terms';

export const routeViewsModule = angular
	.module('app.routeViews', [
		appRootModule,
		courseEditorModule,
		feedbackSessionModule,
		ngxLicenseManagementModule,
		ngxLicenseSeatsManagementModule,
		ngxUserSettingsModule,
		ngxFolderViewModule,
		ngxFoldersViewModule,
		ngxActivityViewModule,
		ngxSessionViewModule,
		feedbackSessionModule,
		activityReportPanelModule.name,
		groupCreatorModule,
		groupsIndexModule,
		groupDepModel,
		groupServiceModule,
		GoLocationModule,
		ngxTermsServiceModule
	])
	.component('quickStartGuideRouteView', QuickStartGuideRouteViewComponent)
	.component('wizardRouteView', WizardRouteViewComponent)
	.component('licenseManagementRouteView', licenseManagementRouteViewComponent)
	.component('licenseSeatsManagementRouteView', licenseSeatsManagementRouteViewComponent)
	.name;
