import { clientSettings, Theme } from 'go-modules/models/common/client.settings';

export class ThemeEngine {

	public static getTheme (appData) {

		const themeName = appData.theme;

		if (!themeName || !clientSettings.themes) {
			return {};
		}

		const theme: Theme = clientSettings.themes[themeName];

		return theme || {};
	}

	public static applyTheme (theme) {
		if (theme.cssVars) {
			Object.keys(theme.cssVars).forEach((cssVar) => {
				document.documentElement.style.setProperty(cssVar, theme.cssVars[cssVar]);
			});
		}
	}

	public static setPageTitle (theme, activity) {
		if (activity) {
			document.title = theme.titlePrefix ? `${theme.titlePrefix} - ${activity.name}` : `GoReact - ${activity.name}`;
		}
	}
}
