import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { ReviewPaymentComponent } from './review-payment.component';

import './style.less';

export const reviewPaymentModule = angular.module('reviewPayment', [
	angularTranslate
])
	.component('reviewPayment', ReviewPaymentComponent)
	.name;
