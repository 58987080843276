import { IComponentOptions } from 'angular';
import { AccountSetupWizardViewController } from './account-setup-wizard-view.controller';

export const AccountSetupWizardViewComponent = {
	controller: AccountSetupWizardViewController,
	require: {
		wizardController: '^wizard'
	},
	template: require('./account-setup-wizard-view.component.html')
} as IComponentOptions;
