import { LinkUserModalController } from './modal.controller';
import template from './modal.html';

/* @ngInject */
export function linkUserModal (goModalBootstrap) {

	function open (options) {
		options.template = template;
		options.controller = LinkUserModalController;
		options.name = options.windowClass = 'link-user-modal';
		options.keyboard = false;
		options.ariaLabelledBy = 'link-user-modal';
		options.backdrop = 'static';

		return goModalBootstrap.open(options);
	}

	return {
		open
	};
}
