import template from './subtotal-details.component.html';
import { SubtotalDetailsController as controller } from './subtotal-details.controller';

export const SubtotalDetailsComponent: ng.IComponentOptions = {
	template,
	controller,
	bindings: {
		subtotal: '<',
		showTaxExempt: '<?',
		isTaxExempt: '='
	},
	require: {
		formController: '^form'
	}
};
