import * as angular from 'angular';
import { clientSettings } from 'go-modules/models/common/client.settings';

/* @ngInject */
export function wrapAppDataRunBlock (appData, User, Group, ActivityModel, UseTypeModel) {
	appData.currentUser = User.model(appData.currentUser);
	appData.userGroup = Group.model(appData.userGroup);
	appData.myAccount = appData.myAccount ? Group.model(appData.myAccount) : null;
	appData.activity = appData.activity ? ActivityModel.model(appData.activity) : null;

	// Store group org setting to clientSettings for org type feature test
	clientSettings.orgSettings = appData.userGroup.org_settings;

	angular.forEach(appData.useTypes, (useType, index) => {
		appData.useTypes[index] = new UseTypeModel(useType);
	});
}
