import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NgxPHPMathRoundService {

	constructor () {}

	/**
	 * A port of _php_math_round to typescript using chatgpt and manually verified
	 * @see https://github.com/php/php-src/blob/da3e4832618d3607dc6e124f8926ba3fbe1083dc/ext/standard/math.c#L125
	 *
	 * Assume that the number we give is not a number larger than
	 * 10^2147483662... (although that doesn't seem possible anyways)
	 *
	 * Rounds a number to a certain number of decimal places in a certain rounding
	 * mode. For the specifics of the algorithm, see http://wiki.php.net/rfc/rounding
	 */
	public round (value: number, places: number): string {
		if (Math.abs(places) >= 23) {
			/*
			* PHP supports places > 22 || < -22, but that requires strtod()
			* We are not using strtod() because it is a 1000 line monster with
			* hundreds of #ifdef statements, depending on how you compile php.
			* Instead we'll throw if the places are out of range. Here's the demon for reference:
			*/

			/* eslint-disable-next-line max-len */
			// @see https://github.com/php/php-src/blob/da3e4832618d3607dc6e124f8926ba3fbe1083dc/Zend/zend_strtod.c#L2524-L3603

			throw new Error('Unsupported number of places. It must be between -22 and 22');
		}

		if (!Number.isFinite(value) || value === 0.0) {
			return value.toFixed(Math.max(0, places));
		}

		let tmp_value: number;

		const precision_places = 14 - Math.floor(Math.log10(Math.abs(value)));

		const f1 = Math.pow(10, Math.abs(places));

		/* If the decimal precision guaranteed by FP arithmetic is higher than
		   the requested places BUT is small enough to make sure a non-zero value
		   is returned, pre-round the result to the precision */
		if (precision_places > places && precision_places - 15 < places) {
			let f2: number = Math.pow(10, Math.abs(precision_places));
			if (precision_places >= 0) {
				tmp_value = value * f2;
			} else {
				tmp_value = value / f2;
			}
			/* preround the result (tmp_value will always be something * 1e14,
			   thus never larger than 1e15 here) */
			tmp_value = Math.floor(Math.abs(tmp_value) + 0.5) * Math.sign(tmp_value);

			/* now correctly move the decimal point */
			f2 = Math.pow(10, Math.abs(places - precision_places));
			/* because places < precision_places */
			tmp_value = tmp_value / f2;
		} else {
			/* adjust the value */
			if (places >= 0) {
				tmp_value = value * f1;
			} else {
				tmp_value = value / f1;
			}
			/* This value is beyond our precision, so rounding it is pointless */
			if (Math.abs(tmp_value) >= 1e15) {
				return value.toFixed(Math.max(0, places));
			}
		}

		tmp_value = Math.floor(Math.abs(tmp_value) + 0.5) * Math.sign(tmp_value);

		if (places > 0) {
			tmp_value = tmp_value / f1;
		} else {
			tmp_value = tmp_value * f1;
		}

		return tmp_value.toFixed(Math.max(0, places));
	}
}
