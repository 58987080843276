import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { countryModule } from 'go-modules/country';
import { CountryPickerComponent } from './country-picker.component';


import './style.less';

export const countryPickerModule = angular.module('countryPicker', [
	angularTranslate,
	countryModule
])
	.component('countryPicker', CountryPickerComponent)
	.name;
