/* @ngInject */
export function AccountSetupWizardViewController (appData) {
	const vm = this;

	vm.$onInit = () => {
		vm.user = appData.currentUser;
		vm.group = appData.userGroup;
		vm.account = appData.myAccount;
		vm.useTypes = appData.useTypes;
	};
}
