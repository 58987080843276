import * as angular from 'angular';

/* @ngInject */
export function wizardContentDirective ($templateCache, $compile) {
	return {
		link: (scope, element, _attrs, wizardController) => {

			// scope for each injected view
			let childScope;

			// compile / append new content when wizard shows frame
			wizardController.wizard.onStart((view) => {
				let template;
				if (view.templateId) {
					template = $templateCache.get(view.templateId);
				} else {
					template = view.template;
				}

				if (!template) {
					throw new Error('Template not found using id ' + view.templateId);
				}

				// use view template
				const tElement = angular.element(template);

				if (childScope) {
					childScope.$destroy();
				}

				// create new child scope
				childScope = scope.$new();

				// extend with data
				angular.extend(scope, view.data || {});

				// empty element first
				element.empty();

				// append element to container
				element.append(tElement);

				// compile template after because it requires
				// a parent controller to be there when compile
				$compile(tElement)(childScope);
			});

			// start wizard
			wizardController.next();

			// scope destroy handler
			scope.$on('$destroy', () => {
				if (childScope) {
					childScope.$destroy();
				}
			});
		},
		require: '^wizard',
		restrict: 'E',
		scope: true
	};
}
