import { Component, Input, OnInit } from '@angular/core';
import { getUrlByLmsName } from 'lti/app-error/lms-instruction-urls';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';
import type { LtiLmsNameMappings } from 'lti/app-error/app-error.controller';

interface MissingLTI1p3DeploymentError {
	missingLti1p3Deployment: {
		missing_deployment: {
			is_migration: boolean;
		};
	};
}
@Component({
	selector: 'missing-deployment-error-renderer',
	template: require('./missing-deployment-error.component.html'),
	styles: [require('./missing-deployment-error.component.scss')]
})
export class MissingDeploymentErrorComponent implements OnInit {
	@Input() public errors: MissingLTI1p3DeploymentError;
	@Input() public lmsName: string;
	@Input() public mappings: LtiLmsNameMappings;

	public setupInstructionsLink: string;
	public contactSupport: string;

	public ngOnInit () {
		this.setupInstructionsLink = getUrlByLmsName(this.lmsName, this.mappings);
		this.contactSupport = helpUrlsLookup.contactSupport;
	}
}
