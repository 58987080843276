import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UseType } from 'go-modules/models/use-type/use-type.interface';
import { BehaviorSubject, forkJoin, of} from 'rxjs';
import type { User } from 'ngx/go-modules/src/interfaces/user';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { UserService } from 'ngx/go-modules/src/services/user/user.service';
import { NgxCourseService } from 'ngx/go-modules/src/services/course/course.service';
import type { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import type { Account } from 'ngx/go-modules/src/interfaces/account';
import { InvalidValueValidator } from 'ngx/go-modules/src/validators/invalid-value';
import { PermanentlyInvalidValidator } from 'ngx/go-modules/src/validators/permanently-invalid';

export interface GroupedUseType {
	name: string;
	types: UseType[];
}

@Component({
	selector: 'ngx-account-editor',
	template: require('./account-editor.component.html'),
	styles: [require('./account-editor.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountEditorComponent implements OnInit {
	@Input()
	public user: User;
	@Input()
	public group: CourseFolder;
	@Input()
	public account: Account;
	@Input()
	public useTypes: UseType[];
	@Output()
	public onLoad = new EventEmitter<boolean>();
	@Output()
	public onValidity = new EventEmitter<boolean>();
	public groupedUseTypes: GroupedUseType[];
	public showVideoPhone$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public form = new FormGroup({
		useType: new FormControl(null, [Validators.required]),
		firstName: new FormControl(null, [Validators.required]),
		lastName: new FormControl(null, [Validators.required]),
		email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(255)]),
		phoneNumber: new FormControl(null, []),
		videoPhoneNumber: new FormControl(null, [])
	});

	constructor (
		private groupService: NgxGroupService,
		private userService: UserService,
		private courseService: NgxCourseService
	) {}

	public ngOnInit (): void {
		this.groupedUseTypes = Object.values(this.useTypes.reduce((result, item) => {
			const { category } = item;
			if (!result[category]) {
				result[category] = { name: category, types: [] };
			}
			result[category].types.push(item);
			return result;
		}, {}));

		if (this.user.first_name) {
			this.form.controls.firstName.setValue(this.user.first_name);
			this.form.controls.firstName.disable();
		}

		if (this.user.last_name) {
			this.form.controls.lastName.setValue(this.user.last_name);
			this.form.controls.lastName.disable();
		}

		if (this.user.email) {
			this.form.controls.email.setValue(this.user.email);
			this.form.controls.email.disable();
		}

		if (!this.account) {
			this.form.controls.useType.clearValidators();
		}

		this.onLoad.emit(true);
		this.emitFormValidity();
	}

	public updateUseType (event) {
		if (event.value.slug === 'sign-language-interpreting') {
			this.showVideoPhone$.next(true);
		} else {
			this.showVideoPhone$.next(false);
		}
		let updateCourse$ = of(null);
		if (!this.group.use_type_id) {
			updateCourse$ = this.courseService.updateCourse({
				...this.group,
				use_type_id: event.value.use_type_id,
				// properties already exist but endpoint expects them in course settings object
				course_settings: {
					start_date: this.group.start_date ?? null,
					end_date: this.group.end_date ?? null,
					product_id: this.group.product_id ?? null
				}
			});
		}
		const updateAccount$ = this.groupService.updateAccount(this.account.group_id, {
			...this.account,
			use_type_id: event.value.use_type_id
		});
		forkJoin([updateAccount$, updateCourse$]).subscribe(() => {
			this.emitFormValidity();
		});
	}

	public updateSelf () {
		if (!this.form.controls.email.disabled && !this.form.controls.email.valid) {
			return;
		}

		this.userService.updateSelf({
			...this.user,
			first_name: this.form.controls.firstName.value,
			last_name: this.form.controls.lastName.value,
			phone_number: this.form.value.phoneNumber,
			video_phone_number: this.form.value.videoPhoneNumber,
			accepted_terms_at: new Date(),
			email: this.form.controls.email.value
		}).subscribe({
			next: () => {
				this.emitFormValidity();
			},
			error: (e) => {
				if (e.error.email_in_use) {
					this.form.controls.email.addValidators(InvalidValueValidator([this.form.controls.email.value]));
					this.form.controls.email.updateValueAndValidity();
					this.emitFormValidity();
				} else if (e.error.support_required) {
					this.form.controls.email.addValidators(PermanentlyInvalidValidator('support'));
					this.form.controls.email.updateValueAndValidity();
					this.emitFormValidity();
				} else {
					this.form.controls.email.addValidators(PermanentlyInvalidValidator('unknown'));
					this.form.controls.email.updateValueAndValidity();
					this.emitFormValidity();
				}
			}
		});
	}

	private emitFormValidity () {
		if (this.form.valid) {
			this.onValidity.emit(true);
		} else {
			this.onValidity.emit(false);
		}
	}
}
