import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { ProductDetailsFormComponent } from './product-details-form.component';
import './style.less';

export const productDetailsFormModule = angular.module('productDetailsFormModule', [
	angularTranslate
])
	.component('productDetailsForm', ProductDetailsFormComponent)
	.name;
