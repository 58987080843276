import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import { CopyPreviousAssignmentModal } from './modal.factory';
import { activityModel } from 'go-modules/models/activity';
import './modal.less';
import { richDropdownModule } from 'go-modules/rich-dropdown';
import { linkUserModalModule } from '../link-user';
import { groupDepModel } from 'go-modules/models/group-dep';

export const copyPreviousAssignmentModalModule = angular.module('modal.copyPreviousAssignment', [
	goModalBootstrapModule,
	angularTranslate,
	richDropdownModule,
	activityModel,
	linkUserModalModule,
	groupDepModel
])
	.service('copyPreviousAssignmentModal', CopyPreviousAssignmentModal)
	.name;
