import { LazyPaymentLoader } from 'go-modules/payment-panel/lazy-payment-loader.service';
import * as angular from 'angular';
import { BillingEntity, GroupService as GrService } from 'go-modules/services/group/group.service';
import { PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';

/* @ngInject */
export class CoursePaymentWizardViewController  {

	public course: any;
	public currentUser: any;
	public wizardController: any;
	public title: string;
	public lazyPayFormPromise: Promise<any>;

	constructor (
		private appData,
		private GroupService: GrService,
		private PaymentLoader: LazyPaymentLoader,
		private $window: angular.IWindowService
	) {}

	public $onInit () {
		this.course = this.appData.userGroup;
		this.currentUser = this.appData.currentUser;
		this.title = '';
	}

	/**
	 * Handles payment success events
	 */
	public paySuccess (billingEntity: BillingEntity) {
		this.appData.userGroup.billing_entity_id = billingEntity.id;
		this.appData.prompts.pay = false;
		this.appData.prompts.setupLicense = false;
		this.$window.sessionStorage.setItem('appData', JSON.stringify(this.appData));
		this.wizardController.next();
	}

	/**
	 * Consume a credit
	 */
	public useCredit () {
		this.GroupService.consumeCredit(this.course.group_id).then((billingEntity: BillingEntity) => {
			this.paySuccess(billingEntity);
		});
	}

	/**
	 * Show the pay form
	 */
	public async goToPayForm (type?: string) {
		await this.PaymentLoader.openPayForm(
			this.currentUser,
			this.course,
			(e) => this.paySuccess(e.billing_entity),
			null,
			type,
			PURCHASE_TYPES.COURSE,
			null
		);
	}
}
