import * as angular from 'angular';
import * as angularMessages from 'angular-messages';
import { downgradeComponent } from '@angular/upgrade/static';
import { appDataModule } from '../app-data';
import { goBootstrapModule } from '../downgraded-app.module';
import { AppErrorComponent } from './app-error.component';
import {
	DefaultErrorRendererComponent
} from './default-error-renderer/error-renderer.component';
import { ActivityNotFoundErrorComponent } from 'ngx/lti/src/app-error/activity-not-found-error/activity-not-found-error.component';
import { InvalidActivityTypeChangeErrorComponent } from 'ngx/lti/src/app-error/invalid-activity-type-change-error/invalid-activity-type-change-error.component';
import {
	ToolUnavailableErrorComponent
} from 'ngx/lti/src/app-error/tool-unavailable-error/tool-unavailable-error.component';
import {
	CourseLicenseExpiredErrorComponent
} from 'ngx/lti/src/app-error/course-license-expired-error/course-license-expired-error.component';
import {
	MissingDeploymentErrorComponent
} from 'ngx/lti/src/app-error/missing-deployment-error/missing-deployment-error.component';
import {
	MissingParametersErrorComponent
} from 'ngx/lti/src/app-error/missing-parameters-error/missing-parameters-error.component';

export const appErrorModule = angular
	.module('app.error', [
		goBootstrapModule,
		angularMessages,
		appDataModule.name
	])
	.component('appError', AppErrorComponent)
	.directive('activityNotFoundErrorRenderer', downgradeComponent({ component: ActivityNotFoundErrorComponent }) as angular.IDirectiveFactory)
	.directive('invalidActivityTypeChangeErrorRenderer', downgradeComponent({ component: InvalidActivityTypeChangeErrorComponent }) as angular.IDirectiveFactory)
	.directive('toolUnavailableError', downgradeComponent({ component: ToolUnavailableErrorComponent }) as angular.IDirectiveFactory)
	.directive('courseLicenseExpiredErrorRenderer', downgradeComponent({ component: CourseLicenseExpiredErrorComponent }) as angular.IDirectiveFactory)
	.directive('missingDeploymentErrorRenderer', downgradeComponent({ component: MissingDeploymentErrorComponent }) as angular.IDirectiveFactory)
	.directive('missingParametersErrorRenderer', downgradeComponent({ component: MissingParametersErrorComponent }) as angular.IDirectiveFactory)
	.component('defaultErrorRenderer', DefaultErrorRendererComponent)
	.name;
