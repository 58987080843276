import 'core-js/features/reflect';

// Angular dependencies
import * as angular from 'angular';

// Other app dependencies
import { ThemeEngine } from './theme';
import { appModule } from './app.module.ajs';
import { appErrorModule } from './app-error';
import { appDataModule } from './app-data';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { goBootstrapModule } from './downgraded-app.module';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { translateConfig } from 'go-modules/translation-helper/translate.config';
import { GoLocalizeHelperService, Language } from 'go-modules/translation-helper/go-localize-helper.service';

import './main.less';

// Run for translations
/** @ngInject */
const transRun = (goLocalizeHelper: GoLocalizeHelperService, appData) => {
	goLocalizeHelper.configure(appData.currentUser.language);
};

// Translation config module
export const transModule = angular.module('bootTransModule', [
	goLocalizeHelperModule,
	appDataModule.name
]).config(translateConfig)
	.run(transRun);

function makeUnknownErrorAppData (errorMessage: string) {
	return {
		errors: {
			// This is okay to be not translated as we failed to
			// load the user's locale, so it'll always be english
			other: 'Unknown Error (' + errorMessage + '). Please try again later or contact support.'
		},
		currentUser: {
			language: Language.EN
		}
	};
}

async function getJsonFromResponse (response: Response) {
	// We have to get text rather than json, as the server might return non-json (i.e. 502 Gateway Error)
	const responseText = await response.text();
	let responseJson;
	try {
		responseJson = JSON.parse(responseText);
	} catch (e) {
		// We got non-json from the server, render it as an "other" error
		return makeUnknownErrorAppData(`${response.status}: ${responseText}`);
	}

	if (response.ok || 'errors' in responseJson) {
		// We got a 200 response or the response json contain an errors key,
		// so we'll assume this is an "appData".
		return responseJson;
	}

	// We got a non-200 response, and the response json doesn't
	// contain an errors key, meaning it is not an "appData".

	let errorMessage;
	if ('exception' in responseJson) {
		// This is most likely laravel handing us an exception json.
		// This will have message, exception, file, line, and a stack trace if APP_DEBUG is true
		const exceptionClass = responseJson.exception.split('\\').reverse()[0];
		errorMessage = `${exceptionClass}(${responseJson.message})` +
			` in ${responseJson.file}:${responseJson.line} (see network for trace log)`;

	} else if ('message' in responseJson) {
		// If APP_DEBUG is false, it'll only have a message.
		errorMessage = responseJson.message;
	} else {
		// Otherwise we don't know what it is, just return it entirely
		errorMessage = JSON.stringify(responseJson, null, '\t');
	}
	return makeUnknownErrorAppData(`${response.status}: ${errorMessage}`);
}

async function fetchAppData (ltiDataKey) {
	const paramsUrl = `${clientSettings.GoReactV2LTI}/v1p1/params?lti-data-key=${ltiDataKey}`;
	let response;
	try {
		response = await globalThis.fetch(paramsUrl, {
			credentials: 'include',
			method: 'POST',
			headers: {
				Accept: 'application/json'
			}
		});
	} catch (e) {
		// Network failed
		return makeUnknownErrorAppData(e.message);
	}

	// Remove the lti-data-key from the url/history
	// ...well not history due to this decade old bug: https://bugzilla.mozilla.org/show_bug.cgi?id=753264#c21
	// But it's better than nothing and will prevent trying to fetch the data from the backend again on refresh
	const newUrl = new URL(location.href);
	newUrl.searchParams.delete('lti-data-key');
	globalThis.history.replaceState(globalThis.history.state, document.title, newUrl.href);

	const appData = await getJsonFromResponse(response);
	globalThis.sessionStorage.setItem('appData', JSON.stringify(appData));
	return appData;
}

(async () => {
	let appData = null;

	// If launching from V2, we set this query parameter to uuid where we can retrieve the appData
	const ltiDataKey = (new URLSearchParams(globalThis.location.search)).get('lti-data-key');

	// If the user has launched before, but then reloaded their page, they'll have their appData in
	// their session storage, since for security reasons, you're only allowed to retreive it from the backend once
	const sessionAppData = JSON.parse(globalThis.sessionStorage.getItem('appData'));

	if ('appData' in globalThis) {
		// Remove this if once V1 is dead
		appData = globalThis.appData;
	} else if (ltiDataKey != null) {
		appData = await fetchAppData(ltiDataKey);
	} else if (sessionAppData != null) {
		appData = sessionAppData;
	} else {
		// The most likely case is the user visited lti.goreact.com directly, as we have no launch parameters
		return globalThis.location.replace('https://help.goreact.com/developer');
	}

	appDataModule.constant('appData', appData);

	// Set client settings values as early as possible
	// Error cases won't have these values, so feature flags
	// are not really available when its an error launch
	globalThis.goSettings.partners = appData.partners;
	globalThis.goSettings.orgList = [ appData.org_id ];
	globalThis.goSettings.orgSettings = appData.org_settings;
	globalThis.goSettings.userId = parseInt(appData.currentUser?.user_id, 10);
	globalThis.goSettings.userCreatedAt = appData.currentUser?.created;

	const theme = ThemeEngine.getTheme(appData);
	ThemeEngine.applyTheme(theme);

	if (appData.errors == null) {
		angular.bootstrap(document.querySelector('#main-app'), [appModule, goBootstrapModule, transModule.name], {strictDi: true});
	} else {
		angular.bootstrap(document.querySelector('#error-app'), [appErrorModule, goBootstrapModule, transModule.name], {strictDi: true});
	}
})();
