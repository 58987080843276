import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WizardViewComponent } from './wizard-view.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [CommonModule, TranslateModule],
	declarations: [WizardViewComponent],
	entryComponents: [WizardViewComponent],
	exports: [WizardViewComponent]
})
export class WizardViewModule {

}

