import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LTIModule } from 'ngx/lti/src/lti.module';
import { GoModulesModule } from 'ngx/go-modules/src/go-modules.module';
import { CommonModule } from '@angular/common';
import { retry } from 'rxjs/operators';
import { GoTranslateLoader } from 'ngx/go-modules/src/services/go-translation/go-translate-loader';
import { InterceptorsModule } from 'ngx/go-modules/src/services/interceptors';
import { WizardViewModule } from './wizards/wizard-view/wizard-view.module';

function initializeApp (translate: TranslateService) {
	// Loads translations prior to app initializing instead of lazy loading
	return () => translate.use('en')
		.pipe(retry(2))
		.toPromise()
		// eslint-disable-next-line no-console
		.catch(console.error);
}

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: GoTranslateLoader.factory,
				deps: [HttpClient]
			}
		}),
		BrowserAnimationsModule,
		CommonModule,
		LTIModule,
		GoModulesModule,
		InterceptorsModule,
		WizardViewModule
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [TranslateService],
			multi: true
		}
	]
})
export class AppModule {
	constructor () {}

	public ngDoBootstrap () {}
}
