import { startCase } from 'lodash';

/* @ngInject */
export function QuickStartGuideController (appData) {
	const vm = this;

	vm.$onInit = () => {
		vm.lmsName = startCase(appData.lmsName);
	};
}
