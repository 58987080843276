import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { AddressDetailsFormComponent } from './address-details-form.component';
import { countryPickerModule } from 'go-modules/country-picker';
import './style.less';

export const addressDetailsFormModule = angular.module('addressDetailsFormModule', [
	angularTranslate,
	countryPickerModule
])
	.component('addressDetailsForm', AddressDetailsFormComponent)
	.name;
