import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { SubtotalDetailsComponent } from './subtotal-details.component';
import './style.less';

export const subtotalDetailsModule = angular.module('subtotalDetailsModule', [
	angularTranslate
])
	.component('subtotalDetails', SubtotalDetailsComponent)
	.name;
