import * as angular from 'angular';
import template from './payment-panel.template.html';

import { PaymentPanelController as controller } from './payment-panel.controller';
import { SidePanelService } from 'go-modules/side-panel/side-panel.service';
import type { GoSidePanelOptions } from 'go-modules/side-panel/side-panel.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class PaymentPanel {
	public static readonly NG1_INJECTION_NAME = 'PaymentPanel' as const;

	/* @ngInject */
	constructor (private SidePanel: SidePanelService) {}

	public open (options, backdrop: any = 'static', extraWindowClass = '', appendToElem = null) {
		const windowClass: string = 'payment-panel' + extraWindowClass;

		const sidePanelData = {
			template,
			bindings: {
				options
			},
			controller,
			windowClass,
			keyboard: false,
			backdrop
		} as GoSidePanelOptions;

		if (appendToElem) {
			sidePanelData.appendTo = angular.element(appendToElem) as ng.IAugmentedJQuery;
		}

		return this.SidePanel.open(sidePanelData);
	}
}

export const paymentPanelToken = upgradeNg1Dependency(PaymentPanel);
