import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { richDropdownModule } from 'go-modules/rich-dropdown';
import { uiDecimal } from 'go-modules/ui-decimal';
import { goPopoverModule } from 'go-modules/go-popover';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';
import { LicenseDetailsFormComponent } from './license-details-form.component';
import { subtotalDetailsModule } from 'go-modules/payment-panel/subtotal-details';
import { ngxAccountServiceModule } from 'ngx/go-modules/src/services/account';
import { templates } from './templates';
import './style.less';

export const licenseDetailsFormModule = angular.module('licenseDetailsFormModule', [
	angularTranslate,
	richDropdownModule,
	ngxGroupServiceModule,
	ngxAccountServiceModule,
	subtotalDetailsModule,
	uiDecimal,
	goPopoverModule
])
	.run(templates)
	.component('licenseDetailsForm', LicenseDetailsFormComponent)
	.name;
