import {ILogService, IAugmentedJQuery} from 'angular';

export type LtiLmsNameMappings = Record<string, string>;
export interface LtiAppDataError {
	errors: any;
	lmsName: string;
	lmsNameMappings: LtiLmsNameMappings;
}
export enum ERROR_RENDERERS {
	ACTIVITY_NOT_FOUND = 'activity-not-found-error',
	INVALID_ACTIVITY_TYPE_CHANGE = 'invalid-activity-type-change-error',
	PARAMETER = 'parameter-error',
	TOOL_UNAVAILABLE = 'tool-unavailable-error',
	COURSE_LICENSE_EXPIRED = 'course-license-expired-error',
	MISSING_DEPLOYMENT = 'missing-deployment-error',
	DEFAULT = 'default-error'
}

export class AppErrorController {
	public errors: any;
	public lmsName: string;
	public errorRenderer: string;
	public mappings: LtiLmsNameMappings;
	public hasTitle: boolean;

	/* @ngInject */
	constructor (public $log: ILogService, private $element: IAugmentedJQuery, appData: LtiAppDataError) {
		this.errors = appData.errors;
		this.lmsName = appData.lmsName;
		this.mappings = appData.lmsNameMappings;
		this.hasTitle = true;
	}

	public $onInit () {

		if (this.errors == null) {
			this.errorRenderer = ERROR_RENDERERS.DEFAULT;
		} else if (this.errors.toolUnavailable) {
			this.errorRenderer = ERROR_RENDERERS.TOOL_UNAVAILABLE;
		} else if (this.errors.requiredParameters) {
			this.errorRenderer = ERROR_RENDERERS.PARAMETER;
		} else if (this.errors.activityNotFound) {
			this.errorRenderer = ERROR_RENDERERS.ACTIVITY_NOT_FOUND;
		} else if (this.errors.invalidActivityTypeChange) {
			this.errorRenderer = ERROR_RENDERERS.INVALID_ACTIVITY_TYPE_CHANGE;
		} else if (this.errors.courseLicenseExpired) {
			this.errorRenderer = ERROR_RENDERERS.COURSE_LICENSE_EXPIRED;
		} else if (this.errors.missingLti1p3Deployment) {
			this.errorRenderer = ERROR_RENDERERS.MISSING_DEPLOYMENT;
		} else {
			this.errorRenderer = ERROR_RENDERERS.DEFAULT;
		}

		this.hasTitle = this.errors != null &&
			!this.errors.duplicateApp &&
			!this.errors.toolUnavailable &&
			!this.errors.courseLicenseExpired &&
			(this.errorRenderer !== ERROR_RENDERERS.DEFAULT);

		this.$element[0].classList.add(this.errorRenderer);

		this.$log.error('errors', this.errors || 'Unknown error during bootstrap');
	}
}
