import { PubnubService } from 'go-modules/go-pubnub/pubnub.service';
import { CONTENT_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { noop } from 'angular';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { ACTIVITY_SOURCES, ActivitySourceDialogComponent } from 'ngx/go-modules/src/components/dialogs/activity-source-dialog/activity-source-dialog.component';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { ActivityEditorAiMarkersTour } from 'go-modules/activity-editor/activity-editor-ai-markers-tour/activity-editor-ai-markers-tour.service';

/* @ngInject */
export function ActivityEditorWizardViewController (
	$scope,
	ActivityModel,
	ActivityTemplateModel,
	Group,
	MediaModel,
	pubnub: PubnubService,
	appData,
	ngxGoModalService: GoModalService,
	libraryCollectionViewerModal,
	copyPreviousAssignmentModal,
	fullstoryService: FullstoryService,
	eventService: EventService,
	featureFlag: FeatureFlag,
	selectedService: SelectedService,
	activityEditorAiMarkersTour: ActivityEditorAiMarkersTour
) {
	const vm = this;
	const environmentVarsService = EnvironmentVarsService.getInstance();

	/**
	 * Handles controller init
	 */
	vm.$onInit = () => {
		// Fetch activity templates
		vm.activityTemplates = [];
		vm.showActivityPanel = true;
		vm.group = appData.userGroup;
		vm.activity = appData.activity;
		vm.user = appData.currentUser;

		// Activity editor header options
		vm.headerOptions = {
			hideDates: true,
			hideGroups: true,
			hideName: true
		};

		if (!vm.group) {
			throw new Error('Param `group` is required by ActivityEditorContainerController');
		} else if (!(vm.activity instanceof ActivityModel)) {
			throw new Error('Param `activity` is required by ActivityEditorContainerController');
		}

		// Fetch org settings
		Group.getOrgSettings({group_id: vm.group.org_id}).then((settings) => {
			vm.orgSettings = settings;
		});

		// Subscribe to pubnub channels
		const pubnubChannel = environmentVarsService.get(EnvironmentVarsService.VAR.USER_PUBNUB_CHANNEL);
		if (!pubnubChannel || pubnubChannel === undefined) {
			vm.channels = [];
		} else {
			vm.channels = [environmentVarsService.get(EnvironmentVarsService.VAR.USER_PUBNUB_CHANNEL)];
		}
		vm.channels.unshift(vm.group.channel);
		pubnub.sub(vm.channels, 'wizard-view', vm.messageHandler);

		if (!vm.activity.template?.id) {
			vm.showActivityPanel = false;
			ActivityTemplateModel.query().$promise.then((templates) => {
				vm.activityTemplates = templates;
				let template = templates.find((t) => t.slug === ActivityTemplateModel.TYPE.STANDARD);
				if (vm.activity.activity_template_id) {
					template = ActivityTemplateModel.model({
						id: vm.activity.activity_template_id
					});
				}
				vm.activity.setActivityTemplate(template);
				vm.showActivityPanel = true;
			});
		} else {
			vm.activityTemplates = ActivityTemplateModel.query();
		}

		// if the activity template is stimulus or comment only then they must have passed in
		// a custom_goreact_activity_type, so don't show the assignment source modal
		if (vm.activity.template?.slug !== ActivityTemplateModel.TYPE.STIMULUS_RESPONSE &&
			vm.activity.template?.slug !== ActivityTemplateModel.TYPE.FEEDBACK_ONLY) {
			ngxGoModalService.open(ActivitySourceDialogComponent, true)
				.afterClosed().subscribe((source: ACTIVITY_SOURCES | undefined) => {
					if (source === ACTIVITY_SOURCES.LIBRARY) {
						libraryCollectionViewerModal.open({
							modalData:{
								filterType: [CONTENT_TYPES.ACTIVITIES],
								mode: MODES.SELECT,
								collectionManagement: true
							}
						}).result
							.then((item: any) => {
								fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_ASSIGNMENT_SOURCE_LIBRARY, {
								});
								const oldActivityName = vm.activity.name;
								// need to remove activity panel and then make a new one
								vm.showActivityPanel = false;
								vm.activity.$copy({
									sourceActivity: item.id
								}).then(() => {
									vm.activity.name = oldActivityName;
									vm.showActivityPanel = true;
								});
							})
							.catch(noop)
							.finally(() => vm.showActivityEditorAiMarkersTour());
					} else if (source === ACTIVITY_SOURCES.PREVIOUS) {
						fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_ASSIGNMENT_SOURCE_PREVIOUS, {});
						copyPreviousAssignmentModal.open({
							modalData: {
								currentAssignment: vm.activity
							}
						}).result
							.then((assignment: any) => {
								const oldActivityName = vm.activity.name;
								// need to remove activity panel and then make a new one
								vm.showActivityPanel = false;
								vm.activity.$copy({
									sourceActivity: assignment.activity_id
								}).then(() => {
									vm.activity.name = oldActivityName;
									vm.showActivityPanel = true;
								});
							})
							.catch(noop)
							.finally(() => vm.showActivityEditorAiMarkersTour());
					} else if (source === ACTIVITY_SOURCES.NEW) {
						fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_ASSIGNMENT_SOURCE_NEW, {});
						vm.showActivityEditorAiMarkersTour();
					} else {
						vm.showActivityEditorAiMarkersTour();
					}
				});
		}
	};

	vm.showActivityEditorAiMarkersTour = () => {
		if (!vm.activity.isConversationBoard() &&
			selectedService.getLicense()?.salesforce_license.ai_prompts_enabled &&
			featureFlag.isAvailable('AI_MARKERS')) {
			activityEditorAiMarkersTour.getTour().start();
		}
	};

	/**
	 * Handles controller destroy
	 */
	vm.$onDestroy = () => {
		// Unsubscribe from pubnub group channel
		pubnub.unsub(vm.channels, 'wizard-view');
	};

	/**
	 * All incoming messages are received here
	 *
	 * @param message
	 */
	vm.messageHandler = (message) => {
		$scope.$apply(() => {
			Object.keys(message).forEach((property) => {
				const item: any = message[property];
				switch (property) {
					case 'media':
						const media = MediaModel.model(item);
						// Broadcast media sync event
						eventService.broadcast(EVENT_NAMES.MEDIA_SYNC, media);
						break;
				}
			});
		});
	};
}
