import { IComponentOptions } from 'angular';
import { CourseSetupWizardViewController } from './course-setup-wizard-view.controller';

export const CourseSetupWizardViewComponent = {
	controller: CourseSetupWizardViewController,
	require: {
		wizardController: '^wizard'
	},
	template: require('./course-setup-wizard-view.component.html')
} as IComponentOptions;
