import { noop } from 'angular';
import { UserService } from 'go-modules/models/user/user.service';

export interface Bindings {
	currentAssignment: any;
}

export class CopyPreviousAssignmentModalController implements Bindings {

	public $close: (result?: any) => boolean;
	public $dismiss: (result?: any) => boolean;
	public currentAssignment: any;
	public courses: any[] = [];
	public assignments: any[] = [];
	public selectedCourse: any = null;
	public selectedAssignment: any = null;

	/* @ngInject */
	constructor (
		private Group,
		private $filter: ng.IFilterService,
		private $translate,
		private $document,
		private ActivityModel,
		private linkUserModal,
		private userService: UserService
	) {}

	public $onInit () {
		this.getCourses().then((courses) => this.courses = courses);
	}

	public onCourseSelection () {
		this.assignments = [];
		this.selectedAssignment = null;

		if (this.selectedCourse) {
			this.getAssignments().then((assignments) => this.assignments = assignments);
		}
	}

	public linkWithAnotherAccount () {
		return this.linkUserModal.open({
			modalData: {
				description: this.$translate.instant('copy-previous-assignment-modal_link-user-description'),
				submitButtonText: this.$translate.instant('copy-previous-assignment-modal_link-user-button')
			}
		}).result.then(() => {
			this.userService.currentUser.is_account_linking_enabled = false;
			this.getCourses().then((courses) => this.courses = courses);
			this.assignments = [];
		}).catch(noop);
	}

	public showAccountLinkSection () {
		return this.userService.currentUser.is_account_linking_enabled;
	}

	public onSubmit (form: ng.IFormController) {
		if (form.$invalid) {
			return;
		}

		this.$close(this.selectedAssignment);
	};

	private getCourses (): ng.IPromise<any[]> {
		return this.Group.getByUser({
			user_id: this.userService.currentUser.user_id,
			onlyLeaf: true,
			explicitAccessOnly: false
		}).$promise.then((courses) => {
			return this.orderCourses(courses);
		});
	}

	// order courses first by start date and then by name (reversed)
	private orderCourses (courses) {
		return this.$filter('orderBy')(courses, ['start_date', 'name'], true);
	}

	private getAssignments (): ng.IPromise<any[]> {
		return this.ActivityModel.getByGroup({group_id: this.selectedCourse.getId()}).$promise.then((assignments) => {
			assignments = this.filterAssignments(assignments);
			return this.orderAssignments(assignments);
		});
	}

	// order assignments first by created date and then by name (reversed)
	private orderAssignments (assignments) {
		return this.$filter('orderBy')(assignments, ['created_at', 'name'], true);
	}

	private filterAssignments (assignments) {
		return this.$filter('filter')(assignments, (assignment: any) => {
			// exclude current assignment
			if (this.currentAssignment && this.currentAssignment.getId() === assignment.getId()) {
				return false;
			} else if (!assignment.template) {
				return false;
			}

			return !assignment.isDefault();
		});
	}
}
