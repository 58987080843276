import { Injectable } from '@angular/core';
import { LICENSE_TRANSACTION_TYPE, PaymentOptionsModalData, PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';

@Injectable({
	providedIn: 'root'
})
export class NgxPurchaseTypeService {

	constructor () {}

	public isCoursePurchase (options: PaymentOptionsModalData): boolean {
		return options.purchaseType === PURCHASE_TYPES.COURSE;
	}

	public isLicensePurchase (options: PaymentOptionsModalData): boolean {
		return options.purchaseType === PURCHASE_TYPES.LICENSE;
	}

	public isLicenseInitialPurchase (options: PaymentOptionsModalData): boolean {
		return this.isLicensePurchase(options) &&
			options.licenseTransactionType === LICENSE_TRANSACTION_TYPE.INITIAL;
	}

	public isLicenseSeatsPurchase (options: PaymentOptionsModalData): boolean {
		return this.isLicensePurchase(options) &&
			options.licenseTransactionType === LICENSE_TRANSACTION_TYPE.SEATS;
	}

	public isLicenseRenewal (options: PaymentOptionsModalData): boolean {
		return this.isLicenseInitialPurchase(options) &&
			options.license !== null;
	}

	public isLicenseRegularPurchase (options: PaymentOptionsModalData): boolean {
		return this.isLicenseInitialPurchase(options) &&
			!this.isLicenseRenewal(options);
	}

	public isLicenseUpgradePurchase (options: PaymentOptionsModalData): boolean {
		return this.isLicensePurchase(options) &&
			options.licenseTransactionType === LICENSE_TRANSACTION_TYPE.UPGRADE;
	}

	public isLicenseModificationPurchase (options: PaymentOptionsModalData): boolean {
		return this.isLicenseSeatsPurchase(options) ||
			this.isLicenseUpgradePurchase(options);
	}
}
