import template from './modal.html';
import { CopyPreviousAssignmentModalController as controller } from './modal.controller';
import { GoModalSettings } from '../go-modal-bootstrap.factory';
import type { Bindings } from './modal.controller';

export interface CopyPreviousAssignmentModalSettings extends GoModalSettings {
	modalData: Bindings;
};

/* @ngInject */
export class CopyPreviousAssignmentModal {

	/* @ngInject */
	constructor (private goModalBootstrap) {}

	public open (options) {
		options.template = template;
		options.controller = controller;
		options.windowClass = options.name = 'copy-previous-assignment-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return this.goModalBootstrap.open(options);
	}
}
